import React, {useState} from 'react'
import {gql, useQuery} from "@apollo/client";
import {useSnackbar} from "notistack";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import {USERS_QUERY} from "../../ConstantsFuncs/constants";
import PropTypes from "prop-types";
import {MEETINGTODO_EDIT_MUTATION} from "../../ConstantsFuncs/mutations";
import {FormOneFieldDatetime} from "../../FormOneField/FormOneFieldDatetime";
import {date} from "yup";
import {FormOneFieldRTFText} from "../../FormOneField/FormOneFieldRTFText";
import FormWrapperForOneField from "../../FormOneField/FormOneFieldWrapper";
import {FormOneFieldCheckboxDialogUsers} from "../../FormOneField/FormOneFieldCheckboxDialogUsers";


const MeetingtodoEditDialog = (props) => {

    const {enqueueSnackbar} = useSnackbar()
    const [meetingtodo, setMeetingtodo] = useState(null)

    const {loading: queryLoading} = useQuery(MEETINGTODO_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {where: {id: props.id}},
        onError: (e) => {
            enqueueSnackbar(`Error! ${e.message}`, {variant: "error"})
        },
        onCompleted: data => {
            setMeetingtodo(data.meetingtodo)
        }
    });


    if (!meetingtodo) return null

    return (
        <Dialog fullWidth maxWidth={"lg"} open={props.open} onClose={() => {
            //setDrawerOpen(false)
            props.onClose()
        }}>
            <DialogTitle>Actiepunt uit {`${meetingtodo.meetingtype.title}`}</DialogTitle>
            <Divider/>
            <DialogContent>
                <FormWrapperForOneField
                    loading={queryLoading}
                    mutation={MEETINGTODO_EDIT_MUTATION}
                    initialValues={meetingtodo}
                    readOnly={meetingtodo.finished !== null}
                >
                    <FormOneFieldDatetime
                        type={"date"}
                        name={"date"}
                        label={"datum"}
                        validation={date().required()}
                    />
                    <FormOneFieldDatetime
                        type={"date"}
                        name={"finished"}
                        label={"afgerond"}
                        minDate={meetingtodo.date}
                        validation={date().nullable()}
                        nullable={true}
                    />
                    <FormOneFieldCheckboxDialogUsers
                        label={"Verantwoordelijken"}
                        name={'users'}
                    />
                    <FormOneFieldRTFText
                        name={"description"}
                        label={"Omschrijving"}
                    />
                </FormWrapperForOneField>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    //setDrawerOpen(false)
                    props.onClose()
                }}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

MeetingtodoEditDialog.propTypes =
    {
        id: PropTypes.string.isRequired
    }

export default MeetingtodoEditDialog


const MEETINGTODO_QUERY = gql`
    query Meetingtodo ($where: MeetingtodoWhereUniqueInput!) {
        meetingtodo (where: $where){
            id
            description
            date
            finished
            ${USERS_QUERY}
            meetingtype{
                id
                title
            }
        }
    }
`;