import {gql} from "@apollo/client";
import {AUTHORIZATIONS_QUERY, FILES_QUERY, USER_QUERY, USERS_QUERY} from "./constants";

export const UPDATE_ONE_MEETING_MUTATION = gql`
    mutation UpdateOneMeeting ($data: MeetingUpdateInput!, $where: MeetingWhereUniqueInput!){
        updateOneMeeting(data: $data, where: $where){
            id
            title
            date
            finished
            president {
                id
                prename
                insertion
                surname
            }
            secretary {
                id
                prename
                insertion
                surname
            }
            attendees {
                id
                prename
                insertion
                surname
            }
            text
            meetingtype {
                id
                title
                defaultSecure
            }
        }
    }
`;

export const CREATE_ONE_AUTHORIZATION_MUTATION = gql`
    mutation CreateOneAuthorization($data: AuthorizationCreateInput!) {
        createOneAuthorization(data: $data){
            id
            authrole{
                id
                title
            }
            ${USER_QUERY}
        }
    }
`;
export const UPDATE_ONE_AUTHORIZATION_MUTATION = gql`
    mutation UpdateOneAuthorization($data: AuthorizationUpdateInput!, $where: AuthorizationWhereUniqueInput!) {
        updateOneAuthorization(data: $data, where: $where){
            id
            authrole{
                id
                title
            }
            ${USER_QUERY}
        }
    }
`;

export const DELETE_ONE_AUTHORIZATION_MUTATION = gql`
    mutation DeleteOneAuthorization($where: AuthorizationWhereUniqueInput!) {
        deleteOneAuthorization(where: $where){
            id
        }
    }
`;
export const DELETE_ONE_ALERT_MUTATION = gql`
    mutation DeleteOneAlert($where: AlertWhereUniqueInput!) {
        deleteOneAlert(where: $where){
            id
        }
    }
`;

export const UPDATE_ONE_ALERT_MUTATION = gql`
    mutation UpdateOneAlert($data: AlertUpdateInput!, $where: AlertWhereUniqueInput!) {
        updateOneAlert(where: $where, data: $data){
            id
            title
            date
            finished
            ${USERS_QUERY}
        }
    }
`;

export const UPDATE_ONE_AUDIT_MUTATION = gql`
    mutation UpdateOneAudit($data: AuditUpdateInput!, $where: AuditWhereUniqueInput!) {
        updateOneAudit(where: $where, data: $data){
            id
            title
            subject
            notes
            isoelements{
                id
                title
                paragraph
            }
            date
            finished
            extern
            externalUsers
            ${FILES_QUERY}
            ${AUTHORIZATIONS_QUERY}
        }
    }
`;
export const UPDATE_ONE_MANUFACTURERREVIEW_MUTATION = gql`
    mutation UpdateOneManufacturerreview($data: ManufacturerreviewUpdateInput!, $where: ManufacturerreviewWhereUniqueInput!) {
        updateOneManufacturerreview(where: $where, data: $data){
            id
            description
            date
            contact
            friendly
            accessability
            speed
            pricequality
            satisfaction
            yearjudgement
            yearjudgementyear
        }
    }
`;

export const CREATE_ONE_MANUFACTURERREVIEW_MUTATION = gql`
    mutation CreateOneManufacturerreview($data: ManufacturerreviewCreateInput!) {
        createOneManufacturerreview(data: $data){
            id
            description
            date
            contact
            friendly
            accessability
            speed
            pricequality
            satisfaction
            yearjudgement
            yearjudgementyear
        }
    }
`;

export const CREATE_ONE_DATERANGE_MUTATION = gql`
    mutation CreateOneDaterange($data: DaterangeCreateInput!) {
        createOneDaterange(data: $data){
            id
            start
            end
        }
    }
`;

export const DELETE_ONE_DATERANGE_MUTATION = gql`
    mutation DeleteOneDaterange($where: DaterangeWhereUniqueInput!) {
        deleteOneDaterange(where: $where){
            id
        }
    }
`;

export const UPDATE_ONE_DATERANGE_MUTATION = gql`
    mutation UpdateOneDaterange($data: DaterangeUpdateInput!, $where: DaterangeWhereUniqueInput!) {
        updateOneDaterange(where: $where, data: $data){
            id
            start
            end
        }
    }
`;

export const UPDATE_ONE_FINDINGTYPE_MUTATION = gql`
    mutation UpdateOneFindingtype ($data: FindingtypeUpdateInput!, $where: FindingtypeWhereUniqueInput!) {
        updateOneFindingtype (data: $data, where: $where){
            id
            title
            model
            viero
            admitted
            meetingtypes{
                id
                title
            }
            users{
                id
                prename
                insertion
                surname
            }
        }
    }
`;
export const UPDATE_ONE_FINDING_MUTATION = gql`
    mutation UpdateOneFinding($data: FindingUpdateInput!, $where: FindingWhereUniqueInput!) {
        updateOneFinding(where: $where, data: $data){
            id
            title
            description
            finished
            document
            oorzaak
            omvang
            oplossing
            operationaliteit
            gravity
            repeat
            critical
            controllable
            detectable
            comments {
                id
                created
                description
                ${USER_QUERY}
            }
            findingtype {
                id
                model
                title
                viero
                admitted
            }
            viero
            isoelements{
                id
            }
            alerts{
                id
                title
                date
                finished
                ${USERS_QUERY}
            }
            admitted{
                id
            }
            createdBy{
                id
                prename
                insertion
                surname
            }
            audit{
                id
                title
            }
            slidesending{
                id
                title
            }
            managementreview{
                id
                year
            }
            qualitydashboard{
                id
                quarter
            }
        }
    }
`;

export const DELETE_ONE_FINDING_MUTATION = gql`
    mutation DeleteOneFinding($where: FindingWhereUniqueInput!) {
        deleteOneFinding(where: $where){
            id
        }
    }
`;

export const CREATE_ONE_ALERT_MUTATION = gql`
    mutation CreateOneAlert($data: AlertCreateInput!) {
        createOneAlert(data: $data){
            id
            title
            date
            ${USERS_QUERY}
        }
    }
`;

export const CREATE_ONE_COMMENT_MUTATION = gql`
    mutation CreateOneComment($data: CommentCreateInput!) {
        createOneComment(data: $data){
            id
            description
            created
            ${USER_QUERY}
        }
    }
`;

export const UPDATE_ONE_DEVICE_MUTATION = gql`
    mutation UpdateOneDevice($data: DeviceUpdateInput!, $where: DeviceWhereUniqueInput!) {
        updateOneDevice(where: $where, data: $data){
            id
            title
            model
            application
            inventorynumber
            received
            inservice
            outservice
            hospital
            location
            active
            manufacturer{
                id
            }
        }
    }
`;

export const UPDATE_ONE_MENUITEM_MUTATION = gql`
    mutation UpdateOneMenuitem($data: MenuitemUpdateInput!, $where: MenuitemWhereUniqueInput!) {
        updateOneMenuitem(where: $where, data: $data){
            id
            title
            icon
            link
            subgroup
            profiles{
                id
            }
        }
    }
`;


export const UPDATE_ONE_USER_MUTATION = gql`
    mutation UpdateOneUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
        updateOneUser(data: $data, where: $where){
            id
            active
            prefix
            prename
            insertion
            surname
            username
            email
            profiles{
                id
                title
            }
            dateranges{
                id
                start
                end
            }
        }
    }
`;

export const UPDATE_ONE_VALIDATION_MUTATION = gql`
    mutation UpdateOneValidation($data: ValidationUpdateInput!, $where: ValidationWhereUniqueInput!) {
        updateOneValidation(data: $data, where: $where){
            id
            created
            finished
            description
            criteria
            method
            result
            conclusion
        }
    }
`;

export const UPDATE_ONE_STOCKITEM_MUTATION = gql`
    mutation UpdateOneStockitem($data: StockitemUpdateInput!, $where: StockitemWhereUniqueInput!) {
        updateOneStockitem(data: $data, where: $where){
            id
            lot
            expiration
            expired
            requested
            requester{
                id
                prename
                insertion
                surname
            }
            ordered
            orderer{
                id
                prename
                insertion
                surname
            }
            order_status
            received
            received_status
            receiver{
                id
                prename
                insertion
                surname
            }
            start
            start_status
            starter{
                id
                prename
                insertion
                surname
            }
            end
            end_status
            ender{
                id
                prename
                insertion
                surname
            }
            stock {
                id
                acceptationtest
                title
            }
            status{
                id
                title
            }
            acceptationtest{
                id
                description
                created
                updated
            }
        }
    }
`;

export const DELETE_ONE_STOCKITEM_MUTATION = gql`
    mutation DeleteOneStockitem($where: StockitemWhereUniqueInput!) {
        deleteOneStockitem(where: $where){
            id
        }
    }
`;


export const UPDATE_MANY_STOCKITEM_MUTATION = gql`
    mutation UpdateManyStockitem($data: StockitemUpdateInput!, $where: StockitemWhereInput!) {
        updateManyStockitem(data: $data, where: $where)
    }
`;

export const UPDATE_ONE_ACCEPTATIONTEST_MUTATION = gql`
    mutation UpdateOneAcceptationtest($data: AcceptationtestUpdateInput!, $where: AcceptationtestWhereUniqueInput!) {
        updateOneAcceptationtest(data: $data, where: $where){
            id
            created
            updated
            finished
            description
            ${USER_QUERY}
        }
    }
`;
export const CHANGE_PASSWORD_MUTATION = gql`
    mutation ChangePassword($oldpass: String!, $newpass: String!) {
        changePassword(oldpass: $oldpass, newpass: $newpass){
            success
        }
    }
`;

export const CREATE_MANY_APPOINTMENT_MUTATION = gql`
    mutation CreateManyAppointment($appointment: AppointmentCreateInput!, $repeat: String!, $enddaterepeat: DateTime!) {
        createManyAppointment(appointment: $appointment, repeat: $repeat, enddaterepeat: $enddaterepeat)
    }
`;



export const CREATE_ONE_APPOINTMENT_MUTATION = gql`
    mutation CreateOneAppointment($data: AppointmentCreateInput!) {
        createOneAppointment(data: $data){
            id
            title
            start
            end
            ${USERS_QUERY}
        }
    }
`;
export const UPDATE_ONE_APPOINTMENT_MUTATION = gql`
    mutation UpdateOneAppointment($data: AppointmentUpdateInput!, $where: AppointmentWhereUniqueInput!) {
        updateOneAppointment(data: $data, where: $where){
            id
            title
            start
            end
            location
            description
            ${USERS_QUERY}
        }
    }
`;
export const DELETE_ONE_APPOINTMENT_MUTATION = gql`
    mutation DeleteOneAppointment($where: AppointmentWhereUniqueInput!) {
        deleteOneAppointment(where: $where){
            id
        }
    }
`;