import React, {useContext, useMemo, useState} from 'react'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from 'prop-types';
import * as _ from "lodash";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import {Box, ButtonGroup, Chip, Divider, FormControl, FormLabel, IconButton} from "@mui/material";
import {FormOneFieldContext} from "./FormOneFieldWrapper";
import Icon from "../Icons/Icon";
import {useQuery} from "@apollo/client";
import {errorHandler, niceUserName} from "../ConstantsFuncs/funcs";
import {PROFILES_WITH_USERS_QUERY} from "../ConstantsFuncs/indexQuery";
import {useSnackbar} from "notistack";


export function FormOneFieldCheckboxDialogUsers(props) {
// todo useEffect goed ingesteld? useReducer gebruiken?

    const {label, readOnly} = props
    const {enqueueSnackbar} = useSnackbar();

    const formContext = useContext(FormOneFieldContext);
    const initialValue = formContext.initialValues[props.name]
    const nInitialValue = useMemo(() => normalizeValue(initialValue), [initialValue])
    const loading = formContext.loading
    const [nValue, setValue] = useState(nInitialValue || [])

    const [nOptions, setNOptions] = useState([])
    const [gOptions, setGOptions] = useState([])

    const [error, setError] = useState('')
    const [checkboxDialogOpen, setCheckboxDialogOpen] = useState(false);
    const buttonLabel = useMemo(() => getButtonLabel(readOnly ? 99999 : props.showCount), [nValue, nOptions])

    const {loading: queryLoading} = useQuery(PROFILES_WITH_USERS_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {
            whereUsers: {
                active: {equals: true}
            }
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
        onCompleted: data => {
            setNOptions(normalizeOptions(data.profiles))
            setGOptions(groupOptions(data.profiles))
        }
    });

    function normalizeOptions(profiles) {

        let nOpts = [];

        for (let profile of profiles) {
            for (let user of profile.users) {
                nOpts.push({id: user.id, label: niceUserName(user)})
            }
        }
        nOpts = _.uniqBy(nOpts, 'id')
        return nOpts
    }

    function groupOptions(profiles) {
        let gOpts = [];

        gOpts = profiles.map(profile => {
            return {
                title: profile.title,
                items: profile.users.map(user => {
                    return {id: user.id, label: niceUserName(user)}
                })
            }
        })

        return gOpts
    }

    function normalizeValue(value) {
        let nValue = [];
        for (let val of value) {
            nValue.push(typeof val === "string" ? val : val.id)
        }
        return nValue
    }


    function getButtonLabel(showCount = props.showCount) {

        let labels = [];
        for (let option of nOptions) {
            if (nValue.includes(option.id)) labels.push(option.label);
            if (labels.length === (showCount || 3)) break
        }
        if (nValue.length > (showCount || 3)) {
            labels.push(` +${nValue.length - 3}`)
        }
        return labels.length > 0 ? _.join(labels, ', ') : 'Maak een keuze'
    }

    function onClose(newValue) {
        setCheckboxDialogOpen(false);
        setValue(newValue)
        // if (newValue) {
        //     props.onChange(resultAsListOfStrings ? makeListOfStrings(newValue) : newValue)
        // }
    }


    const isDirty = useMemo(() => {
        return _.difference(nValue, nInitialValue).length > 0 || _.difference(nInitialValue, nValue).length > 0
    }, [nValue, nInitialValue])


    const getIcon = () => {
        if (loading) return 'loading'
        return isDirty ? 'save' : 'check'
    }

    if (readOnly) {
        return (
            <Box sx={{marginBottom: "8px", marginTop: '16px', display: "flex"}}>
                <TextField
                    name={props.name}
                    error={!!error}
                    helperText={error}
                    value={buttonLabel}
                    label={`${props.label}${isDirty ? " *" : ""}`}
                    type="text"
                    placeholder={label}
                    margin="normal"
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    fullWidth
                    disabled
                />
            </Box>
        )
    }

    return (
        <Box sx={{marginBottom: "8px", marginTop: '16px', display: "flex"}}>
            <TextField
                name={props.name}
                fullWidth
                label={label}
                error={!!error}
                helperText={error}
                variant="outlined"
                value={buttonLabel}
                onClick={() => {
                    if (!readOnly) setCheckboxDialogOpen(true)
                }}
                color={isDirty ? "secondary" : "primary"}


                type="text"
                placeholder={label}
                //margin="normal"
                InputLabelProps={{shrink: true}}

            />
            {isDirty &&
                <ButtonGroup sx={{display: "flex", maxHeight: "56px"}} variant={"text"}>
                    <IconButton
                        color={"warning"}
                        disabled={!isDirty || loading || !!error}
                        onClick={() => {
                            formContext.onChange({
                                [props.name]: {
                                    set: nValue.map(nVal => {
                                        return {id: nVal}
                                    })
                                }
                            })
                        }}><Icon name={getIcon()}/></IconButton>
                    <IconButton
                        onClick={() => {
                            setValue(nInitialValue)
                        }}
                    ><Icon name={'reset'}/></IconButton>
                </ButtonGroup>}
            <FormOneFieldCheckboxDialogDialogUsers
                options={nOptions}
                gOptions={gOptions}
                value={nValue}
                open={checkboxDialogOpen}
                onClose={onClose}
            />
        </Box>

    );
}

FormOneFieldCheckboxDialogUsers.propTypes = {
    readOnly: PropTypes.bool,
    maximum: PropTypes.number,
    showCount: PropTypes.number
}

FormOneFieldCheckboxDialogUsers.defaultProps = {
    maximum: 0,
    readOnly: false,
    showCount: 3
};


function FormOneFieldCheckboxDialogDialogUsers(props) {

    const {options, gOptions, onClose, value, open} = props;
    const [newValue, setNewValue] = React.useState(value);

    function handleCancel() {
        setNewValue(value);
        onClose(value);
    }

    function handleOk() {
        onClose(newValue);
    }

    function handleChange(event) {
        if (event.target.checked) {
            setNewValue([...newValue, event.target.value])
        } else {
            handleDelete(event.target.value)
        }
    }

    function selectAll(event) {
        if (newValue.length === options.length) {
            setNewValue([])
        } else {
            let newVal = [];
            for (let opt of options) {
                newVal.push(opt.id)
            }
            setNewValue(newVal)
        }
    }

    function selectGroup(group) {
        const nv = [...newValue, ...group.items.map(item => {
            return item.id
        })]
        setNewValue(nv.filter((v, i, a) => a.indexOf(v) === i))
    }

    function getCurrentValueLabel() {
        let labels = [];
        for (let option of options) {
            if (newValue.includes(option.id)) labels.push(option);
        }
        return labels
    }

    function handleDelete(dValue) {
        setNewValue(newValue.filter(v => (v !== dValue)))
    }

    return (
        <Dialog open={open} onClose={handleCancel} maxWidth={"md"} fullWidth>
            <DialogTitle>Maak een keuze</DialogTitle>
            <Divider/>
            <DialogContent dividers sx={{padding: "8px 16px", flexShrink: 0}}>
                <Button color={"secondary"} onClick={selectAll}>Selecteer alle</Button>
            </DialogContent>
            <DialogContent dividers sx={{padding: "8px 16px", flexShrink: 0}}>
                {getCurrentValueLabel().map((val, i) => (
                        <Chip
                            key={i}
                            label={val.label}
                            onDelete={() => handleDelete(val.id)}
                            sx={{margin: "4px"}}
                        />
                    )
                )}
            </DialogContent>
            <DialogContent dividers>
                {gOptions.map((gOption, i1) => {
                    return (
                        <FormControl component={'fieldset'} variant="outlined" key={i1} fullWidth>
                            {gOptions.length > 0 &&
                                <Button sx={{position: "absolute", top: -24, right: 0}} onClick={() => {
                                    selectGroup(gOption)
                                }}>allen</Button>}
                            {gOptions.length > 0 && <FormLabel component="legend">{gOption.title}</FormLabel>}
                            <FormGroup>
                                {gOption.items.map(option => {
                                    return (
                                        <FormControlLabel
                                            key={option.id}
                                            control={<Checkbox checked={newValue.includes(option.id)}
                                                               onChange={handleChange} value={option.id}/>}
                                            label={option.label}
                                        />
                                    )
                                })}
                            </FormGroup>
                        </FormControl>
                    )
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Annuleren
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )

}

FormOneFieldCheckboxDialogDialogUsers.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.array.isRequired,
};
