import React from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {Box} from "@mui/material";
import Icon from "../../Icons/Icon";

const classes = {
    root: {
        position: 'absolute',
        top: -15,
        right: -5,
        backgroundColor: "#fff"
    }
}

const SuggestionDropdown = props => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    if (props.suggestions.length === 0) return null


    return (
        <Box sx={classes.root}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Icon name={"list"}/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.suggestions.map((sugg, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                handleClose()
                                props.selectSuggestion(sugg.text)
                            }}>
                            {sugg.text}
                        </MenuItem>
                    )
                )}
            </Menu>
        </Box>
    )
}

SuggestionDropdown.propTypes = {
    suggestions: PropTypes.array.isRequired,
    selectSuggestion: PropTypes.func.isRequired
}

export default SuggestionDropdown