import React, {useEffect, useState} from "react";
import {Form} from "react-final-form";
import Button from "@mui/material/Button";
import {validate} from "validate.js";
import PropTypes, {string} from 'prop-types';
import {isObject, mapFormChildren} from "../ConstantsFuncs/funcs";
import Grid from "@mui/material/Grid";
import Loading from "../Form/Loading";
import AutoSave from "../Form/AutoSave";
import {PASSWORD_VALIDATOR} from "../ConstantsFuncs/constants";
import {ReadOnlyContext} from "../Form/FormItems/ReadOnlyContext";
import {Box} from "@mui/material";

var merge = require('lodash/merge');

const debounce = 1000;


const FormWrapper = props => {

    const fChildren = mapFormChildren(props.children)

    const normalizeInitialValues = (initV) => {
        if (initV === null) return null
        let nValues = {};
        for (let child of fChildren) {
            const initialValueName = child.props.initialValueName || child.props.name
            if (initV.hasOwnProperty(initialValueName) && initV[initialValueName] !== undefined) { //todo verwijderd:  && props.initialValues[initialValueName] !== null
                if (Array.isArray(initV[initialValueName])) {
                    // array of IdObjects
                    nValues[child.props.name] = initV[initialValueName].map(item => {
                        return {id: item.id}
                    })
                } else if (initV[initialValueName] !== null && initV[initialValueName].id !== undefined) {
                    // single IdObject
                    nValues[child.props.name] = {id: initV[initialValueName].id}
                } else {
                    // other, string, integer, bool, json, null
                    nValues[child.props.name] = initV[initialValueName]
                }
            }
        }
        return nValues
    }

    const [initialValues, setInitialValues] = useState(normalizeInitialValues(props.initialValues))
    useEffect(() => {
        setInitialValues(normalizeInitialValues(props.initialValues))
    }, [props.initialValues]);


    const onSubmit = (fieldValues, form) => {
        //let submitValues = Object.assign({}, fieldValues); // clone object because fieldValues is reference
        // console.log('values to mutation', fieldValues);
        // console.log('initialValues', initialValues);

        // restructure values to suit Input type => connect/set for relational fields
        // use connect for new items (no itemId) and set for updating items
        // values: empty array, array of IdObjects, IdObject
        let submitValues = {}
        for (const [key, value] of Object.entries(fieldValues)) {
            if (props.excludeVariable.indexOf(key) === -1) {
                if (props.itemId) {
                    // update fields
                    if (value === null) {
                        // eerst null afvangen
                        submitValues[key] = value //{set: value}
                    } else if (value.id !== undefined) {
                        // idObject
                        submitValues[key] = {connect: value}
                    } else if (!Array.isArray(value) && isObject(value)) {
                        submitValues[key] = value
                    } else {
                        submitValues[key] = {set: value}
                    }
                } else {
                    if (value === null) {
                        // eerst null afvangen
                        submitValues[key] = value
                    } else if (Array.isArray(value) || value.id !== undefined) {
                        // array, idObject
                        submitValues[key] = {connect: value}
                    } else {
                        submitValues[key] = value
                    }
                }
            }
        }
        if (props.itemId) {
            submitValues = {[props.mutationVarName]: submitValues, where: {id: props.itemId}}
        } else {
            submitValues = {[props.mutationVarName]: submitValues}
        }

        submitValues = merge({}, props.extraVariables, submitValues) // Object.assign({}, props.extraVariables, submitValues)
        props.save({variables: submitValues})

    }


//
//
//<pre>{JSON.stringify(values, 0, 2)}</pre>

    const validateForm = (values) => {
        validate.validators.arrayIdObject = function (value, options, key, attributes) {
            if (validate.isArray(value)) {
                return value.reduce((c, v) => {
                    if (validate.isObject(v) && v.hasOwnProperty("id")) {
                        return c
                    }
                    return c + " Foutieve invoer"
                }, "")
            }
            return "foutieve invoer";
        };

        validate.validators.idObject = function (value, options, key, attributes) {
            if (options.allowEmpty && !value) return ""
            if (validate.isObject(value) && value.hasOwnProperty("id")) {
                return ""
            }
            return "foutieve invoer";
        };

        validate.validators.json = function (value, options, key, attributes) {
            if (options.allowEmpty && !value) return ""
            if (validate.isObject(value)) return ""
            return "foutieve invoer";
        };

        validate.validators.dateTimeOrNull = function (value, options, key, attributes) {
            if (value === null) return undefined
            return validate.single(value, {presence: true, datetime: true});
        };

        validate.validators.dateOrNull = function (value, options, key, attributes) {
            if (value === null) return undefined
            return validate.single(value, {presence: true, datetime: true}); //todo datetime => date
        };

        validate.validators.newpassword = function (value, options, key, attributes) {
            // todo
            if (validate.isString(value)) {
                return validate.single(value, {pattern: PASSWORD_VALIDATOR})
            }

        };

        validate.extend(validate.validators.datetime, {
            // The value is guaranteed not to be null or undefined but otherwise it
            // could be anything.
            parse: function (value, options) {
                //return +moment.utc(value);
                //console.log(value)
                return value//DateTime().fromString(value);
            },
            // Input is a unix timestamp
            format: function (value, options) {
                //console.log(value)
                return value //DateTime.fromJSDate(value).toISO()
            }
        });
        validate.extend(validate.validators.date, {
            // The value is guaranteed not to be null or undefined but otherwise it
            // could be anything.
            parse: function (value, options) {
                // return +moment.utc(value);
                //console.log(value)
                return value
                // return DateTime().fromJSDate(value);

            },
            // Input is a unix timestamp
            format: function (value, options) {
                //console.log(value)
                return value
                // return DateTime.fromJSDate(value).toISO()
                // return moment.utc(value).format("YYYY-MM-DD hh:mm"); // tijdelijk ivm opslaan datum in DB
                // return moment.utc(value).format("YYYY-MM-DD");
            }
        });
        const constraints = collectConstraints()
        const v = validate(values, constraints);
        return v
    }

    const collectConstraints = () => {
        let constraints = {};
        for (let child of fChildren) {
            if (child.props.validation) {
                constraints[child.props.name] = child.props.validation
            }
        }
        return constraints
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ReadOnlyContext.Provider value={props.readOnly}>
                    <Loading loading={props.loading}/>
                    {initialValues !== null && <Form
                        onSubmit={!props.readOnly ? onSubmit : () => {
                        }}
                        initialValues={initialValues}
                        validate={validateForm}
                        keepDirtyOnReinitialize={props.keepDirtyOnReinitialize}
                        decorators={props.decorators}


                        render={({handleSubmit, valid, submitError, values}) => {
                            // console.log(values)
                            return (
                                <form onSubmit={handleSubmit}>
                                    {props.children}
                                    {submitError && <Box sx="error">{submitError}</Box>}
                                    {
                                        !props.readOnly && props.autoSave &&
                                        <AutoSave debounce={debounce} save={handleSubmit}/>
                                    }
                                    {
                                        !props.readOnly && !props.autoSave &&
                                        <Button
                                            variant={"outlined"}
                                            onClick={handleSubmit}
                                            disabled={!valid || props.loading}>{props.buttonText}</Button>
                                    }
                                    {!props.readOnly && <input type={"submit"} style={{display: "none"}}/>}
                                </form>

                            )
                        }
                        }

                    />}
                </ReadOnlyContext.Provider>
            </Grid>
        </Grid>
    )


}


export default FormWrapper

FormWrapper.propTypes = {
    readOnly: PropTypes.bool,
    autoSave: PropTypes.bool,
    save: PropTypes.func,
    initialValues: PropTypes.object,
    buttonText: PropTypes.string,
    keepDirtyOnReinitialize: PropTypes.bool,
    loading: PropTypes.bool,
    itemId: PropTypes.string,
    mutationVarName: PropTypes.string,
    extraVariables: PropTypes.object,
    extraVars: PropTypes.object,
    excludeVariable: PropTypes.arrayOf(string),
    decorators: PropTypes.array
};

FormWrapper.defaultProps = {
    readOnly: false,
    autoSave: true,
    children: [],
    initialValues: null, //{},
    buttonText: "opslaan",
    keepDirtyOnReinitialize: true,
    mutationVarName: 'data',
    extraVariables: {},
    extraVars: {},
    excludeVariable: [],
    decorators: []
};

