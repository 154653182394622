import React from "react";
import * as _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useLocation} from "react-router-dom";
import {ContentState, convertFromRaw, EditorState} from "draft-js";
import {DateTime} from "luxon";

export const valueGetterDate = (value) => {
    return niceDateTime(value, 'date', '')
}

export const valueGetterDatetime = (value) => {
    return niceDateTime(value, 'datetime', '')
}


export function niceDateTime(datetime, format = "datetime", message = "n.v.t.") {
    if (!datetime) return message;
    if (format === "date") return DateTime.fromISO(datetime).toFormat("DD");//DateTime.DATE_SHORT
    if (format === "time") return DateTime.fromISO(datetime).toFormat('T');//DateTime.TIME_24_SIMPLE
    if (format === "datetime") return DateTime.fromISO(datetime).toFormat("ff");//DateTime.DATETIME_SHORT
    return DateTime.fromISO(datetime).toFormat(format)
}

export function getTodayLuxon() {
    return DateTime.local().set({hour: 0, minute: 0, second: 0, millisecond: 0})
}


export function niceUserName(user, backOnly = false) {
    // console.log(user)
    if (user) {
        const {prename, insertion, surname} = user
        if (backOnly) return (insertion ? insertion + ' ' : '') + surname
        return `${prename} ${insertion ? insertion + ' ' : ''} ${surname}`
    }
    return ''
}


export function implodeUsers(users) {
    return implodeOn(users, 'surname')
}

export function implodeOn(items, on = 'title') {
    return _.map(items, on).join(', ')
}

export function implodeAuthUsers(auths) {
    return auths.map((auth => {
        return auth.user.surname
    })).join(', ')
}

export function monthToText(month) {
    const months = [
        'januari', 'februari', 'maart', 'april', 'mei', 'juni',
        'juli', 'augustus', 'september', 'oktober', 'november', 'december'
    ]
    return months[month]
}


export function normalizeOptions(options, idName, labelName) {
    let nOptions = [];
    for (let option of options) {
        nOptions.push({
            id: option[idName],
            label: _.reduce(_.values(_.pick(option, labelName)), (sum, val) => {
                return sum + (val ? `${val} ` : '')
            }, ''),
        })
    }
    return nOptions
}

export function getAttrValue(attr, element, type, def) {
    const attribute = _.find(attr, ['title', element])
    if (attribute) {
        return attribute[`value${attribute.valuetype}`]
    }
    return def
}


export function getInitialValuesFromAttributes(attrs) {
    let vals = {}
    for (let attr of attrs) {
        vals[attr.title] = attr[`value${attr.valuetype}`]
    }
    return vals
}

export const stripTypenameAndId = values => {
    return JSON.parse(JSON.stringify(values), (key, value) => {
        return (key === '__typename' || key === 'id') ? undefined : value
    })
}

export const nl2br = t => {
    return t.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
    })
}


export const errorHandler = (apolloError, snackbar) => {

    if (apolloError.message) {
        snackbar(apolloError.message, {variant: 'error'})
    } else {
        snackbar('andere error', {variant: 'error'})
    }
}


export const mapFormChildren = (children) => {
    let response = []

    React.Children.forEach(children, child => {
        if (React.isValidElement(child)) {
            if (child.props.children) {
                response = [...response, ...mapFormChildren(child.props.children)]
            } else {
                response.push(child);
            }
        }
    });

    return response
}


export const abstractMessage = message => {
    return `*****************\n${message.text}`
}

export function __n(v) {
    return v ? v : ''
}


export function userHasActivity(user, activityId, activities) {
    for (let tActivity of activities) {
        if (tActivity.activity.id === activityId && tActivity.user.id === user.id) {
            return true
        }
    }
    return false
}


export function userHasProfile(user, profileId) {
    for (let profile of user.profiles) {
        if (profile.id === profileId) return true
    }
    return false
}


export function niceKPINorm(norm, unit, validation) {
    if (unit === 'percentage') {
        return validationAsSign(validation) + ' ' + norm.toFixed(1) + '%'
    }
    return validationAsSign(validation) + ' ' + norm.toFixed(1)
}

export function validationAsSign(v) {
    const vals = validations();
    for (let validation of vals) {
        if (validation.id === v) return validation.sign
    }
    return ''
}

export function validations() {
    return [
        {id: 'eq', sign: '=', title: 'gelijk aan'},
        {id: 'gt', sign: '>', title: 'groter dan'},
        {id: 'gte', sign: '>=', title: 'groter dan of gelijk aan'},
        {id: 'lt', sign: '<', title: 'kleiner dan'},
        {id: 'lte', sign: '<=', title: 'kleiner dan of gelijk aan'},
    ]
}

export function niceFrequency(f) {
    const freqs = frequencies();
    for (let freq of freqs) {
        if (freq.id === f) return freq.title
    }
    return 'onbekend'
}

export function frequencies() {
    return [
        {id: 'YEAR', title: 'jaarlijks'},
        {id: 'HALF_YEAR', title: 'halfjaarlijks'},
        {id: 'QUARTER', title: 'elk kwartaal'},
        {id: 'MONTH', title: 'maandelijks'},
        {id: 'WEEK', title: 'wekelijks'},
        {id: 'DAY', title: 'dagelijks'},
        {id: 'HOUR', title: 'elk uur'},
        {id: 'NONE', title: 'niet van toepassing'}
    ]
}

export function useLocationQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const acceptationtestCompleted = (stockitem) => {
    if (stockitem.stock.acceptationtest) {
        return stockitem.acceptationtest && stockitem.acceptationtest.finished !== null
    }
    return null
};


export function useURLQueries() {
    return new URLSearchParams(useLocation().search);
}

export function isObject(val) {
    if (val === null) {
        return false;
    }
    return ((typeof val === 'object'));
}

export function IsJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getStickerClickItem(IdObjectArray, type) {
    let text = ''
    for (let IdObject of IdObjectArray) {
        text += '^II^TS001^ONBarcode100h^DI00h00hA' + type + '_' + IdObject.id + '^FF'
    }

    const element = document.createElement("a");
    const file = new Blob([text], {type: 'text/escp'});
    element.href = URL.createObjectURL(file);
    element.download = "print.escp";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

export const getAbsentStatus = (userId, absents) => {
    for (let absent of absents) {
        if (absent.user.id === userId) {
            return {
                absentId: absent.id,
                absentreasonId: absent.absentreason.id,
                title: absent.absentreason.title
            }
        }
    }
    return {
        absentId: null,
        absentreasonId: null,
        title: 'aanwezig'
    }
}

export const formatFormHelperText = texts => {
    return (
        <List dense disablePadding>
            {texts.map((text, index) => (
                <ListItem key={index}>
                    <ListItemText
                        primary={text}
                    />
                </ListItem>
            ))}
        </List>
    )
}

export const formatSortBy = (o, columns) => {
    if (!o || o.length === 0) return undefined
    //return o
    return o.map(item => {
        if (columns.find(v => v.field === item.field)?.nullable) {
            return {
                [item.field]: {
                    sort: item.sort
                }
            }
        } else {
            return {
                [item.field]: item.sort
            }
        }
    })
}


export const createWhereClause = (searchTexts, searchFields, filter) => {
    if (Array.isArray(searchTexts) && searchTexts.length > 0 && searchFields.length > 0) {
        const items = []
        searchFields.forEach(searchField => {
            searchTexts.forEach(searchText => {
                items.push({[searchField]: {contains: searchText}})
            })
        })
        if (filter !== {}) {
            return {AND: [filter, (items.length > 1) ? {OR: items} : items[0]]}
        }
        return (items.length > 1) ? {OR: items} : items[0]
    }
    return filter

}
export const createDateFilter = (searchDateFrom, searchDateTo, searchFields) => {
    const items = {
        from: searchDateFrom !== '' && searchDateFrom !== null && searchDateFrom !== undefined,
        to: searchDateTo !== '' && searchDateTo !== null && searchDateTo !== undefined,
    }
    if ((items.from === false && items.to === false) || searchFields.length === 0) return undefined

    return {
        OR: searchFields.map(searchField => {
            if (items.from !== false && items.to !== false) {
                return {
                    AND: [
                        {[searchField]: {gte: searchDateFrom}},
                        {[searchField]: {lte: searchDateTo}}
                    ]
                }
            }
            if (items.from !== false) {
                return {[searchField]: {gte: searchDateFrom}}
            }
            if (items.to !== false) {
                return {[searchField]: {lte: searchDateTo}}
            }

            return undefined
        })
    }
}

export const authModelTitle = (auth, model) => {
    try {
        switch (model) {
            case "slidesending":
            case "audit":
                return auth[model].title
            case "finding":
                return auth.finding.title
            case "qualitydashboard":
                return `Kwartaal ${auth.qualitydashboard.quarter} (${auth.qualitydashboard.managementreview.year})`
            case "managementreview":
                return `MR ${auth.managentreview.year}`
            case "maintenancedone":
                return `${auth.maintenancedone.maintenancetype.title} bij ${auth.maintenancedone.device.title}`
            case "validation":
                return `Validatie ${auth.validation.maintenancedone.maintenancetype.title} bij ${auth.validation.maintenancedone.device.title}`
            default:
                return "onbekend"
        }
    } catch (e) {
        return `error - ${auth.id}`
    }
}


export const authModelName = (auth, model) => {
    switch (model) {
        case "slidesending":
            return "Rondzending"
        case "audit":
            return "Audit"
        case "finding":
            return auth.finding.findingtype.title
        case "qualitydashboard":
            return "Kwartaalbeoordeling"
        case "managementreview":
            return `Managementreview`
        case "validation":
            return `Validatie`
        case "maintenancedone":
            return `Onderhoud`

        default:
            return "onbekend"
    }
}

export const authModelNameFromAuth = (auth) => {
    if (auth.audit !== null) {
        return 'audit'
    }
    if (auth.finding !== null) {
        return 'finding'
    }
    if (auth.managementreview !== null) {
        return `managementreview`
    }
    if (auth.qualitydashboard !== null) {
        return `qualitydashboard`
    }
    if (auth.slidesending !== null) {
        return `slidesending`
    }
    if (auth.maintenancedone !== null) {
        return `maintenancedone`
    }
    if (auth.validation !== null) {
        return `validation`
    }
    return null
}
export const authModelLink = (auth) => {
    if (auth.audit !== null) {
        return `/audits/view/${auth.audit.id}`
    }
    if (auth.finding !== null) {
        return `/findings/view/${auth.finding.id}`
    }
    if (auth.managementreview !== null) {
        return `/managementreviews/view/${auth.managementreview.id}`
    }
    if (auth.qualitydashboard !== null) {
        return `/qualitydashboards/view/${auth.qualitydashboard.id}`
    }
    if (auth.slidesending !== null) {
        return `/slidesendings/view/${auth.slidesending.id}`
    }
    if (auth.validation !== null) {
        return `/validations/view/${auth.validation.id}`
    }
    if (auth.maintenancedone !== null) {
        return `/maintenancedones/view/${auth.maintenancedone.id}`
    }
    return null
}

export function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function truncateDraft(raw, charCount = 50) {
    console.log("truncateDraft")
    const editorState = EditorState.createWithContent(convertFromRaw(raw));

    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();

    let index = 0;
    let currentLength = 0;
    let isTruncated = false;
    const truncatedBlocks = [];

    while (!isTruncated && blocks[index]) {
        const block = blocks[index];
        const length = block.getLength();
        if (currentLength + length > charCount) {
            isTruncated = true;
            const truncatedText = block
                .getText()
                .slice(0, charCount - currentLength);
            const state = ContentState.createFromText(`${truncatedText}...`);
            truncatedBlocks.push(state.getFirstBlock());
        } else {
            truncatedBlocks.push(block);
        }
        currentLength += length + 1;
        index++;
    }

    if (isTruncated) {
        const state = ContentState.createFromBlockArray(truncatedBlocks);
        return EditorState.createWithContent(state);
    }

    return editorState;
}

export const riskBgColor = (gravity, repeat) => {
    const score = riskScore(gravity, repeat)

    if (score >= 15) return "red"
    if (score >= 9 && score < 15) return "orange"
    if (score >= 5 && score < 9) return "yellow"
    if (score <= 4 && score >= 1) return "green"
    return "grey"
}

export const continueRisk = (risk) => {
    const score = riskScore(risk.gravity, risk.repeat)
    if (score < 9 && !risk.critical) return false
    return risk.controllable ? false : !risk.detectable
}

export const riskScore = (gravity, repeat) => {
    return gravity * repeat
}

export const startdateFromQuarterYear = (quarter, year) => {
    if (quarter === 1) return new Date(`${year}-01-01 00:00:000`)
    if (quarter === 2) return new Date(`${year}-04-01 00:00:000`)
    if (quarter === 3) return new Date(`${year}-07-01 00:00:000`)
    if (quarter === 4) return new Date(`${year}-10-01 00:00:000`)
}

export const enddateFromQuarterYear = (quarter, year) => {
    if (quarter === 1) return new Date(`${year}-03-31 23:59`)
    if (quarter === 2) return new Date(`${year}-06-30 23:59`)
    if (quarter === 3) return new Date(`${year}-09-30 23:59`)
    if (quarter === 4) return new Date(`${year}-12-31 23:59`)
}
export const capitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);

}

export const findByDaypart = (workplans, type) => {
    for (const wp of workplans) {
        if (wp.daypart === type) return wp
    }
    return null
}
export const findById = (array, id) => {
    for (const x of array) {
        if (x.id === id) return x
    }
    return null
}
export const findIndexById = (array, id) => {
    let count = 0;
    for (const x of array) {
        if (x.id === id) return count
        count++
    }
    return -1
}

export const rDaypart = {
    MORNING: 'ochtend',
    AFTERNOON: 'middag',
    EVENING: 'avond',
}

export const getAvatarColor = (workplan, active = true) => {
    switch (workplan?.task) {
        case "Aanwezig":
        case "Coupes":
            switch (workplan?.department?.title) {
                case "Bergen op Zoom":
                    return active ? "#70a1e7" : "#cdd5e1"
                case "Tilburg":
                    return active ? "#59a871" : "#c4e0c8"
                case "Digitaal":
                    return active ? "#8f5fea" : "#cdbfe7"
                default:
                    return active ? "#707070" : "#bebebe"
            }
        case "Dienst":
            return "#566b77"
        case "Administratie":
            return "#eca871"
        case "Management":
            return "#eca871"
        case "Vrij":
            return "#ea905f"
        case "Cursus":
            return "#eedf56"
        default:
            return "#f8cdcd"
    }
}