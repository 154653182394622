import React, {useContext, useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {WORKPLANS_QUERY} from "../../ConstantsFuncs/indexQuery";
import {errorHandler} from "../../ConstantsFuncs/funcs";
import {useSnackbar} from "notistack";
import {
    DELETE_WORKPLAN_MUTATION,
    TOGGLE_AUTHORIZE_WORKPLAN_MUTATION,
    UPSERT_WORKPLAN_MUTATION
} from "../../ConstantsFuncs/mutations";
import AuthContext from "./AuthContext";

export const WorkplanContext = React.createContext(
    {
        workplans: [],
        loading: false,
        locations: [],
        setLocations: () => {
        },
        loadWorkplans: () => {
        },
        deleteWorkplan: () => {
        },
        upsertWorkplan: () => {
        },
        mergeWorkplans: () => {
        },
        authorizeWorkplan: () => {
        },
        isEditor: {
            edit: false,
            auth: false
        }
    }
);

export const WorkplanProvider = props => {

    const {enqueueSnackbar} = useSnackbar()
    const userContext = useContext(AuthContext)

    const [users, setUsers] = useState([])
    const [workplans, setWorkplans] = useState([])
    const [locations, setLocations] = useState(["1", "2", "3"])
    const [isEditor, setIsEditor] = useState({
        edit: false,
        auth: false
    })

    useEffect(() => {
        setIsEditor({
            edit: userContext.userInProfile('PATHOLOGIST_AGENDA_EDITORS'),
            auth: userContext.userInProfile('PATHOLOGIST_AGENDA_AUTHORIZE')
        })
    }, [userContext.user])

    const [loadWorkplans, {
        startPolling,
        stopPolling,
        loading: loadWorkplansQueryLoading
    }] = useLazyQuery(WORKPLANS_QUERY, {
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
        onCompleted: data => {
            mergeWorkplans(data.workplans)
        }
    });

    const [authorizeWorkplan, {
        loading: authorizeWorkplanQueryLoading,
    }] = useMutation(TOGGLE_AUTHORIZE_WORKPLAN_MUTATION, {
        onCompleted: (d) => {
            enqueueSnackbar('Geautoriseerd', {variant: "success"})
            // deleteWPFromRow(d.toggleAutorizeWorkplan.id)
            mergeWorkplans([d.toggleAutorizeWorkplan])
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
        //refetchQueries: ['']
    })

    const [deleteWorkplan, {loading: deleteWorkplanMutationLoading}] = useMutation(DELETE_WORKPLAN_MUTATION, {
        onCompleted: (d) => {
            enqueueSnackbar('Verwijderd', {variant: "success"})
            setWorkplans(workplans.filter((wp) => wp.id !== d.deleteOneWorkplan.id))
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
    })

    const [upsertWorkplan, {loading: upsertWorkplanMutationLoading}] = useMutation(UPSERT_WORKPLAN_MUTATION, {
        onCompleted: (d) => {
            enqueueSnackbar('Opgeslagen', {variant: "success"})
            mergeWorkplans([d.upsertOneWorkplan])

        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        }
    })

    const mergeWorkplans = (wps) => {
        // console.log("mergeWorkplans")
        // console.log(wps)
        const wpsIndices = wps.map(iWPS => iWPS.id)
        // console.log(wpsIndices)
        const filtered = workplans.filter((wp) => !wpsIndices.includes(wp.id))
        setWorkplans([...filtered, ...wps])
    }

    return (
        <WorkplanContext.Provider value={{
            workplans: workplans,
            loading: loadWorkplansQueryLoading || authorizeWorkplanQueryLoading || upsertWorkplanMutationLoading || deleteWorkplanMutationLoading,
            locations: locations,
            setLocations: setLocations,
            loadWorkplans: loadWorkplans,
            deleteWorkplan: deleteWorkplan,
            upsertWorkplan: upsertWorkplan,
            mergeWorkplans: mergeWorkplans,
            authorizeWorkplan: authorizeWorkplan,
            isEditor: isEditor
        }}>
            {props.children}
        </WorkplanContext.Provider>
    )
}