import React, {useContext, useEffect, useState} from "react";
import {Box, ButtonGroup, IconButton, TextField} from "@mui/material";
import Icon from "../Icons/Icon";
import {FormOneFieldContext} from "./FormOneFieldWrapper";

export const FormOneFieldText = props => {

    const formContext = useContext(FormOneFieldContext);
    const initialValue = formContext.initialValues[props.name]
    const loading = formContext.loading
    const [value, setValue] = useState(initialValue || '')
    const [error, setError] = useState('')

    const isDirty = (value !== initialValue)

    const getIcon = () => {
        if (loading) return 'loading'
        return isDirty ? 'save' : 'check'
    }

    useEffect(() => {
        async function asyncEffect() {
            try {
                if (props.validation) {
                    await props.validation.validate(value)
                }
                setError('')
            } catch (err) {
                setError(err.errors)
            }
        }

        asyncEffect()
    }, [value]);

    function saveNewValue() {
        formContext.onChange({
            [props.name]: {
                set: value
            }
        })
    }

    return (
        <Box sx={{marginBottom: "8px", marginTop: '16px', display: "flex"}}>
            <TextField
                name={props.name}
                fullWidth
                label={`${props.label}${isDirty ? " *" : ""}`}
                error={!!error}
                helperText={error}
                variant="outlined"
                value={value}
                onChange={(v) => {
                    setValue(v.target.value)
                }}
                color={isDirty ? "secondary" : "primary"}
                placeholder={props.label}
                InputLabelProps={{shrink: true}}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        if (!error) saveNewValue()
                        ev.preventDefault();
                    }
                }}
                disabled={formContext.readOnly}
            />
            {isDirty &&
                <ButtonGroup sx={{display: "flex", maxHeight: "56px"}} variant={"text"}>
                    <IconButton
                        color={"warning"}
                        disabled={!isDirty || loading || !!error}
                        onClick={saveNewValue}><Icon name={getIcon()}/></IconButton>
                    <IconButton
                        onClick={() => {
                            setValue(initialValue)
                        }}
                    ><Icon name={'reset'}/></IconButton>
                </ButtonGroup>}
        </Box>
    )
}

FormOneFieldText.defaultProps = {
    helpText: " djfg sjdf shd fjh ksjdhfk jhsdf kjh"
}