import React from 'react'
import {FormSpy} from 'react-final-form'

var _ = require('lodash');


class AutoSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {values: props.values, submitting: false, error: true}
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(this.save, this.props.debounce)
    }

    save = async () => {
        if (this.promise) {
            await this.promise
        }

        const {values, save, hasValidationErrors} = this.props;

        //console.log(this.state.values)
        //console.log(values)

        if (!hasValidationErrors && !_.isEqual(this.state.values, values)) {
            // values have changed
            this.setState({submitting: true, values});
            this.promise = save(values);
            await this.promise;
            delete this.promise;
            this.setState({submitting: false})
        }
        //}

    };

    render() {
        // This component doesn't have to render anything, but it can render
        // submitting state.
        return null;
        //return this.state.submitting ? <IconBusy /> : <IconDone />
    }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
export default props => (
    <FormSpy {...props} subscription={{values: true, submitting: true, hasValidationErrors: true}}
             component={AutoSave}/>
)
