import React, {useContext, useMemo, useState} from 'react'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from 'prop-types';
import TextField from "@mui/material/TextField";
import {Box, ButtonGroup, Chip, Divider, IconButton, Radio, RadioGroup, Stack} from "@mui/material";
import {FormOneFieldContext} from "./FormOneFieldWrapper";
import Icon from "../Icons/Icon";
import {useQuery} from "@apollo/client";
import {USER_LIST_QUERY} from "../ConstantsFuncs/indexQuery";
import {errorHandler, niceUserName} from "../ConstantsFuncs/funcs";
import {useSnackbar} from "notistack";


export function FormOneFieldRadioDialogUsers(props) {

    const {
        label, readOnly,
    } = props
    const {enqueueSnackbar} = useSnackbar();

    const formContext = useContext(FormOneFieldContext);
    const initialValue = formContext.initialValues[props.name]?.id || null
    const loading = formContext.loading
    const [nValue, setValue] = useState(initialValue)
    const [nOptions, setNOptions] = useState([])
    const [error, setError] = useState('')
    const [radioDialogOpen, setRadioDialogOpen] = useState(false);
    const buttonLabel = useMemo(() => getButtonLabel(), [nValue, nOptions])

    const {loading: queryLoading} = useQuery(USER_LIST_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {
            where:{
                active: {equals: true}
            }
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
        onCompleted: data => {
            setNOptions(normalizeOptions(data.users))
        }
    });

    function normalizeOptions(users) {
        return users.map(user => {
            return {id: user.id, label: niceUserName(user)}
        })
    }


    function getButtonLabel() {
        if (nValue) {
            for (let option of nOptions) {
                if (option.id === nValue) return option.label;
            }
        }
        return 'Maak een keuze'
    }

    function onClose(newValue) {
        setRadioDialogOpen(false);
        setValue(newValue)
        // if (newValue) {
        //     props.onChange(resultAsListOfStrings ? makeListOfStrings(newValue) : newValue)
        // }
    }


    const isDirty = initialValue !== nValue

    const getIcon = () => {
        if (loading) return 'loading'
        return isDirty ? 'save' : 'check'
    }

    if (readOnly) {
        return (
            <Box sx={{marginBottom: "8px", marginTop: '16px', display: "flex"}}>
                <TextField
                    name={props.name}
                    error={!!error}
                    helperText={error}
                    value={buttonLabel}
                    label={`${props.label}${isDirty ? " *" : ""}`}
                    type="text"
                    placeholder={label}
                    margin="normal"
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    fullWidth
                    disabled
                />
            </Box>
        )
    }

    return (

        <Box sx={{marginBottom: "8px", marginTop: '16px', display: "flex"}}>
            <TextField
                name={props.name}
                fullWidth
                label={label}
                error={!!error}
                helperText={error}
                variant="outlined"
                value={buttonLabel}
                onClick={() => {
                    if (!readOnly) setRadioDialogOpen(true)
                }}
                color={isDirty ? "secondary" : "primary"}


                type="text"
                placeholder={label}
                //margin="normal"
                InputLabelProps={{shrink: true}}

            />
            {isDirty &&
                <ButtonGroup sx={{display: "flex", maxHeight: "56px"}} variant={"text"}>
                    <IconButton
                        color={"warning"}
                        disabled={!isDirty || loading || !!error}
                        onClick={() => {
                            formContext.onChange({
                                [props.name]: {
                                    connect: {id: nValue}
                                }
                            })
                        }}><Icon name={getIcon()}/></IconButton>
                    <IconButton
                        onClick={() => {
                            setValue(initialValue)
                        }}
                    ><Icon name={'reset'}/></IconButton>
                </ButtonGroup>}
            <FormOneFieldRadioDialogDialogUsers
                options={nOptions}
                value={nValue}
                open={radioDialogOpen}
                onClose={onClose}
            />
        </Box>

    );
}

FormOneFieldRadioDialogUsers.propTypes = {
    resultAsListOfStrings: PropTypes.bool,
    readOnly: PropTypes.bool,
    maximum: PropTypes.number,
    groupItemsName: PropTypes.string,
    groupTitleName: PropTypes.string,
    groupedOptions: PropTypes.bool
}

FormOneFieldRadioDialogUsers.defaultProps = {
    idName: "id",
    labelName: "title",
    groupItemsName: 'users',
    groupTitleName: 'title',
    groupedOptions: false,
    maximum: 0,
    readOnly: false,
    resultAsListOfStrings: false,
};


function FormOneFieldRadioDialogDialogUsers(props) {

    const {options, onClose, value, open, readOnly} = props;
    const [newValue, setNewValue] = React.useState(value);

    function handleCancel() {
        setNewValue(value);
        onClose(value);
    }

    function handleOk() {
        onClose(newValue);
    }

    function handleChange(event) {
        setNewValue(event.target.value)
    }

    function getCurrentValueLabel() {
        if (newValue) {
            for (let option of options) {
                if (option.id === newValue) return option.label;
            }
        }
        return 'Niets geselecteerd'
    }


    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Maak een keuze</DialogTitle>
            <Divider/>
            <DialogContent sx={{flexShrink: '0', padding: "8px 16px"}}>
                <Stack direction="row" spacing={1}>
                    <Chip label={getCurrentValueLabel()} variant="outlined"/>
                </Stack>
            </DialogContent>
            <Divider/>
            <DialogContent>
                <RadioGroup
                    aria-label={'label'} name={'name'}
                    value={newValue}
                    onChange={handleChange}
                    sx={{width: "100%"}}
                >
                    {options.map(option => {
                        return (
                            <FormControlLabel
                                control={
                                    <Radio/>
                                }
                                value={option.id}
                                label={option.label}
                                key={option.id}
                                disabled={readOnly}
                            />

                        )

                    })}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Annuleren
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )

}

FormOneFieldRadioDialogDialogUsers.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string,
};
