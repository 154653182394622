import {gql} from '@apollo/client';
import {AUTHORIZATIONS_QUERY, FILES_QUERY, USER_QUERY, USERS_QUERY} from "./constants";


export const MESSAGES_COUNT_QUERY = gql`
    query MessagesCount{
        messagesCount {
            count
        }
    }
`;

export const MESSAGE_INDEX_QUERY = gql`
    query MessageIndex($input: MessageIndexInput!){
        messageIndex (input: $input){
            inbox
            send
            archive
            messages{
                id
                subject
                seen
                from {
                    id
                    prename
                    insertion
                    surname
                }
                created
            }
        }
    }
`;

export const MESSAGE_VIEW_QUERY = gql`
    query MessageView($id: String!){
        messageView (id: $id){
            id
            subject
            seen
            from {
                id
                prename
                insertion
                surname
            }
            created
            archive
            text
        }
    }
`;


export const PROFILE_INDEX_QUERY = gql`
    query Profiles ($take: Int, $skip: Int, $where: ProfileWhereInput, $orderBy: [ProfileOrderByWithRelationInput!]){
        profiles(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            absentMarker
        }
    }
`;


export const PROJECT_EDIT_QUERY = gql`
    query ProjectEdit ($where: ProjectWhereUniqueInput!) {
        project (where: $where){
            id
            title
            start
            deadline
            finished
            remarks
            owners{
                id
                prename
                insertion
                surname
            }
            issuers{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const PROFILE_EDIT_QUERY = gql`
    query ProfileEdit ($where: ProfileWhereUniqueInput!) {
        profile (where: $where){
            id
            title
            menuitems {
                id
            }
        }
        menuitems{
            id
            title
        }

    }
`;
export const NOTIFICATION_EDIT_QUERY = gql`
    query NotificationList {
        notificationList {
            meetingtodos{
                id
                date
                meetingtype{
                    id
                    title
                }
            }
            alerts{
                id
                title
                date
                finding{
                    id
                    title
                }
            }
            authorizations{
                id
                authrole{
                    id
                    title
                }
                slidesending{
                    id
                    title
                }
                audit{
                    id
                    title
                }
                finding{
                    id
                    title
                    findingtype{
                        id
                        title
                    }
                }
                managementreview{
                    id
                    year
                }
                qualitydashboard{
                    id
                    quarter
                    managementreview{
                        id
                        year
                    }
                }
                validation{
                    id
                }
                maintenancedone{
                    id
                    date
                    maintenancetype{
                        id
                        title
                    }
                    device{
                        id
                        title
                    }
                }
            }
        }
    }
`;

export const CREATE_ONE_QUARPA_MUTATION = gql`
    mutation CreateOneQuarpa($data: QuarpaCreateInput!) {
        createOneQuarpa(data: $data){
            id
            title
        }
    }
`;

export const CREATE_ONE_RISKGROUP_MUTATION = gql`
    mutation CreateOneRiskgroup($data: RiskgroupCreateInput!) {
        createOneRiskgroup(data: $data){
            id
            title
        }
    }
`;
export const CREATE_ONE_RISK_MUTATION = gql`
    mutation CreateOneRisk($data: RiskCreateInput!) {
        createOneRisk(data: $data){
            id
            act
            created
        }
    }
`;


export const CREATE_ONE_INDICATOR_MUTATION = gql`
    mutation CreateOneIndicator($data: IndicatorCreateInput!) {
        createOneIndicator(data: $data){
            id
            title
        }
    }
`;
export const UPDATE_ONE_INDICATOR_MUTATION = gql`
    mutation UpdateOneIndicator($data: IndicatorUpdateInput!, $where: IndicatorWhereUniqueInput!) {
        updateOneIndicator(data: $data, where: $where){
            id
            title
            frequency
            validation
            norm
            unit
        }
    }
`;

export const UPDATE_ONE_INDICATORVALUE_MUTATION = gql`
    mutation UpdateOneIndicatorvalue($data: IndicatorvalueUpdateInput!, $where: IndicatorvalueWhereUniqueInput!) {
        updateOneIndicatorvalue(data: $data, where: $where){
            id
            date
            value
        }
    }
`;

export const USER_EDIT_QUERY = gql`
    query UserEdit ($where: UserWhereUniqueInput!) {
        user (where: $where){
            id
            active
            prefix
            prename
            insertion
            surname
            username
            email
            profiles{
                id
                title
            }
        }
        profiles {
            id
            title
        }
    }
`;

export const ACTIONNAME_EDIT_QUERY = gql`
    query Actionname ($where: ActionnameWhereUniqueInput!) {
        actionname(where: $where){
            id
            title
            profiles{
                id
                title
            }
        }
        profiles{
            id
            title
        }
    }
`;

//alert! deprecated
export const DAYABSENT_PROFILES_ABSENT_INDEX_QUERY = gql`
    query DayProfilesAbsentIndex($date: String!, $profileId: String, $usersId: [String!]!){
        singleDayEdit(date: $date){
            day{
                id
                date
                users(profileId: $profileId, usersId: $usersId){
                    id
                    prename
                    insertion
                    surname
                    dayabsent(date: $date){
                        id
                        remarks
                        absentreason{
                            id
                            title
                        }
                    }
                }
            }
            absentreasons{
                id
                title
            }

        }
    }
`


export const ACTIVITY_EDIT_QUERY = gql`
    query ActivityEdit ($where: ActivityWhereUniqueInput!) {
        activity(where: $where) {
            id
            title
            max
            profile{
                id
            }
        }
        profiles {
            id
            title
        }
    }
`;


export const ALERT_EDIT_QUERY = gql`
    query AlertEdit ($where: AlertWhereUniqueInput!) {
        alert (where: $where) {
            id
            title
            date
            users{
                id
            }
        }
        ${USERS_QUERY}
    }
`;

export const APPOINTMENT_EDIT_QUERY = gql`
    query AppointmentEdit ($where: AppointmentWhereUniqueInput!) {
        appointment(where: $where) {
            id
            title
            start
            end
            description
            location
            ${USERS_QUERY}
        }
        profiles{
            id
            title
            ${USERS_QUERY}
        }
    }
`;

export const MENUITEM_EDIT_QUERY = gql`
    query MenuitemEdit ($where: MenuitemWhereUniqueInput!) {
        menuitem(where: $where){
            id
            title
            icon
            link
            subgroup
            profiles{
                id
            }
        }
        profiles{
            id
            title
        }
    }
`;

export const ABSENT_EDIT_MULTIPLE_QUERY = gql`
    query AbsentEditMultiple {
        absentEditMultiple {
            ${USERS_QUERY}
            absentreasons{
                id
                title
            }
        }
    }
`;


export const USER_FROM_TOKEN_QUERY = gql`
    query UserFromToken{
        me{
            id
            prefix
            prename
            insertion
            surname
            username
            email
            active
            profiles {
                id
                title
            }
        }
        menuitemsList{
            id
            rang
            title
            link
            icon
            subgroup
        }
        notificationCount
    }
`;

export const STOCK_EDIT_QUERY = gql`
    query StockEdit ($where: StockWhereUniqueInput!){
        stock(where: $where){
            id
            title
            text
            manufacturer{
                id
            }
            artnumberintern
            artnumberextern
            hospital
            location
            minimum
            acceptationtest
            active
            automatic
            groupedStockitems {
                shortage
            }
        }
        manufacturers(where: {active: {equals: true}}) {
            id
            title
            contact
        }
    }
`;
/*

stockitemstatuses{
            title
            stockitems(where: {stock:{id: {equals: $id}}}){
                id
                acceptationtest{
                    id
                    description
                }
                end
                start
                received
                expiration
                lot
            }
        }

        ${USERS_ACTIVE_QUERY}
 */


export const STOCKITEM_EDIT_QUERY = gql`
    query StockitemEdit($where: StockitemWhereUniqueInput!) {
        stockitem(where: $where){
            id
            lot
            expiration
            expired
            requested
            requester{
                id
                prename
                insertion
                surname
            }
            ordered
            orderer{
                id
                prename
                insertion
                surname
            }
            order_status
            received
            received_status
            receiver{
                id
                prename
                insertion
                surname
            }
            start
            start_status
            starter{
                id
                prename
                insertion
                surname
            }
            end
            end_status
            ender{
                id
                prename
                insertion
                surname
            }
            acceptationtest{
                id
                created
                updated
                finished
                description
                ${USER_QUERY}
            }
            stock{
                id
                title
                acceptationtest
            }
            status{
                id
                title
            }
            order{
                id
                date
                received
                ${USER_QUERY}
            }
        }
        ${USERS_QUERY}
    }
`;


export const ACTIVITY_INDEX_QUERY = gql`
    query Activities($take: Int, $skip: Int, $where: ActivityWhereInput, $orderBy: [ActivityOrderByWithRelationInput!]){
        activities (take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            max
            profile {
                id
                title
            }
        }
        aggregateActivity(where: $where){
            _count{
                id
            }
        }
    }
`;

export const SLIDESENDING_INDEX_QUERY = gql`
    query Slidesendings($take: Int, $skip: Int, $where: SlidesendingWhereInput, $orderBy: [SlidesendingOrderByWithRelationInput!]){
        slidesendings(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            location
            created
            deadline
            finished
        }
        aggregateSlidesending(where: $where){
            _count{
                id
            }
        }
    }
`;

export const RISKGROUP_INDEX_QUERY = gql`
    query Riskgroups($take: Int, $skip: Int, $where: RiskgroupWhereInput, $orderBy: [RiskgroupOrderByWithRelationInput!]){
        riskgroups(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
        }
        aggregateRiskgroup(where: $where){
            _count{
                id
            }
        }
    }
`;

export const RISK_INDEX_QUERY = gql`
    query Risks($take: Int, $skip: Int, $where: RiskWhereInput, $orderBy: [RiskOrderByWithRelationInput!]){
        risks(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            act
            process
            failure
            gravity
            repeat
            critical
            controllable
            detectable
            created
        }
        aggregateRisk(where: $where){
            _count{
                id
            }
        }
    }
`;


export const INDICATOR_INDEX_QUERY = gql`
    query Indicators($take: Int, $skip: Int, $where: IndicatorWhereInput, $orderBy: [IndicatorOrderByWithRelationInput!]){
        indicators(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            frequency
            validation
            norm
            unit
        }
        aggregateIndicator(where: $where){
            _count{
                id
            }
        }
    }
`;
export const INDICATOR_EDIT_QUERY = gql`
    query Indicator($where: IndicatorWhereUniqueInput!){
        indicator(where: $where) {
            id
            title
            frequency
            validation
            norm
            unit
            values{
                id
                value
                date
            }
        }
    }
`;


export const GET_ONE_QUARPA_QUERY = gql`
    query Quarpa($where: QuarpaWhereUniqueInput!){
        quarpa(where: $where) {
            id
            title
            finished
            description
        }
    }
`;

export const GET_ONE_RISKGROUP_QUERY = gql`
    query Riskgroup($where: RiskgroupWhereUniqueInput!){
        riskgroup(where: $where) {
            id
            title
            created
            description
        }
    }
`;

export const GET_ONE_RISK_QUERY = gql`
    query Risk($where: RiskWhereUniqueInput!){
        risk(where: $where) {
            id
            act
            process
            failure
            gravity
            repeat
            critical
            controllable
            detectable
            created
            description
            riskgroup{
                id
                title
            }
            oorzaak
            omvang
            oplossing
            operationaliteit
            viero
        }
    }
`;

export const AUDIT_MIRROR_QUERY = gql`
    query AuditMirror ($auditFilter: AuditWhereInput!){
        isoelements (orderBy: [{title: asc}]){
            id
            title
        }
        audits(where: $auditFilter){
            id
            date
            findings{
                id
                finished
                findingtype{
                    id
                    title
                }
                isoelements{
                    id
                }
            }
        }
    }
`;


export const MANAGEMENTREVIEW_INDEX_QUERY = gql`
    query Managementreviews ($take: Int, $skip: Int, $where: ManagementreviewWhereInput, $orderBy: [ManagementreviewOrderByWithRelationInput!]){
        managementreviews(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            year
            qualitydashboards{
                id
                quarter
            }
        }
    }
`;
export const MAINTENANCETYPE_INDEX_QUERY = gql`
    query MaintenancetypeIndex($filter: MaintenancetypeIndexFilterInput){
        maintenancetypeIndex (filter: $filter){
            id
            title
        }
    }
`;

export const ISOELEMENTS_QUERY = gql`
    query Isoelements($where: IsoelementWhereInput, $orderBy: [IsoelementOrderByWithRelationInput!], $take: Int, $skip: Int) {
        isoelements(take: $take, skip: $skip, where: $where, orderBy: $orderBy){
            id
            title
            paragraph
        }
    }
`;

export const APPOINTMENT_ADD_QUERY = gql`
    query AppointmentAdd {
        appointmentAdd {
            ${USERS_QUERY}
        }
    }
`;

export const ACTIVITY_ADD_QUERY = gql`
    query ActivityAdd {
        activityAdd {
            profiles {
                id
                title
            }
        }
    }
`;

export const ALERT_ADD_QUERY = gql`
    query AlertAdd {
        alertAdd {
            ${USERS_QUERY}
        }
    }
`;


export const GET_ONE_SLIDESENDING_QUERY = gql`
    query Slidesending ($where: SlidesendingWhereUniqueInput!) {

        slidesending(where: $where){
            id
            title
            location
            description
            result
            deadline
            created
            finished
            ${AUTHORIZATIONS_QUERY}
            ${FILES_QUERY}
        }

    }

`;


export const GET_MANY_FINDINGTYPES_QUERY = gql`
    query Findingtypes ($where: FindingtypeWhereInput, $findingFilter: FindingWhereInput) {
        findingtypes(where: $where) {
            id
            title
            findings(where: $findingFilter){
                id
                title
                isoelements{
                    id
                }
                findingtype{
                    id
                    title
                }
                created
                createdBy{
                    id
                    prename
                    insertion
                    surname
                }
                finished
            }
        }
    }
`;


export const SLIDESENDING_EDIT_QUERY = gql`
    query SlidesendingEdit ($id: String!, $findingFilter: FindingWhereInput) {
        slidesendingEdit (id: $id){
            slidesending{
                id
                title
                description
                result
                deadline
                created
                finished
                ${AUTHORIZATIONS_QUERY}
                ${FILES_QUERY}
            }
            findingtypes {
                id
                title
                findings(where: $findingFilter){
                    id
                    title
                    isoelements{
                        id
                    }
                    findingtype{
                        id
                        title
                    }
                    created
                    createdBy{
                        id
                        prename
                        insertion
                        surname
                    }
                    finished
                }
            }
        }
    }
`;


export const GET_ONE_QUALITYDASHBOARD_QUERY = gql`
    query Qualitydashboard ($where: QualitydashboardWhereUniqueInput!) {
        qualitydashboard(where: $where){
            id
            quarter
            finished
            qualitydashboarditems{
                id
                qualitydashboarditemtype{
                    id
                    title
                }
                introduction
                evaluation
                conclusion
            }
            managementreview{
                id
                year
            }
        }
    }
`;

export const QUALITYDASHBOARD_EDIT_QUERY = gql`
    query QualitydashboardEdit ($where: QualitydashboardWhereUniqueInput!, $findingFilter: FindingWhereInput, $findingtypeFilter: FindingtypeWhereInput) {
        qualitydashboard(where: $where){
            id
            quarter
            finished
            qualitydashboarditems{
                id
                qualitydashboarditemtype{
                    id
                    title
                }
            }
            managementreview{
                id
                year
            }
            ${AUTHORIZATIONS_QUERY}
            ${FILES_QUERY}
        }
        findingtypes(where: $findingtypeFilter){
            id
            title
            findings(where: $findingFilter){
                id
                title
                createdBy{
                    id
                    surname
                    insertion
                    prename
                }
                created
            }
        }
    }
`;

export const GET_ONE_QUALITYDASHBOARDITEM_QUERY = gql`
    query Qualitydashboarditem ($where: QualitydashboarditemWhereUniqueInput!) {
        qualitydashboarditem(where: $where){
            id
            qualitydashboarditemtype{
                id
                title
            }
            introduction
            evaluation
            conclusion
        }
    }
`;

export const QUALITYDASHBOARDITEM_EDIT_QUERY = gql`
    query QualitydashboarditemEdit ($id: String!, $year: Int!) {
        qualitydashboarditemEdit(id: $id){
            qualitydashboarditem {
                id
                qualitydashboarditemtype{
                    id
                    title
                }
                introduction
                evaluation
                conclusion
            }
            findinggroup{
                title
                description
                groupedfindings{
                    title
                    findings{
                        id
                        title
                        created
                        createdBy{
                            id
                            prename
                            insertion
                            surname
                        }
                        finished
                    }
                }
            }
            auditgroup{
                title
                description
                audits{
                    id
                    title
                    date
                    finished
                }
            }
            slidesendings{
                id
                title
                deadline
                finished
            }
            manufacturers{
                id
                title
                manufacturerreviews(where: {yearjudgement: {equals: true}, yearjudgementyear: {equals: $year}}){
                    id
                    date
                    description
                }
            }
        }
    }
`;


export const MANAGEMENTREVIEW_EDIT_QUERY = gql`
    query Managementreview ($where: ManagementreviewWhereUniqueInput!) {
        managementreview(where: $where){
            id
            year
            finished
            conclusion
            qualitydashboards{
                id
                quarter
                qualitydashboarditems{
                    id
                    qualitydashboarditemtype{
                        id
                        title
                    }
                    introduction
                    evaluation
                    conclusion
                }
            }
        }
        qualitydashboarditemtypes{
            id
            title
        }
    }
`;


export const AUDIT_EDIT_QUERY = gql`
    query Audit ($where: AuditWhereUniqueInput!) {
        audit(where: $where) {
            id
            title
            subject
            notes
            isoelements{
                id
                title
                paragraph
            }
            date
            finished
            extern
            externalUsers
        }
        isoelements{
            id
            title
            paragraph
        },
    }
`;


export const AUTHORIZATIONS_EDIT_QUERY = gql`
    query AuthorizationEdit ($where: AuthorizationWhereUniqueInput!) {
        authorization(where: $where){
            id
            date
            authrole{
                id
                title
            }
            ${USER_QUERY}
        }
        authroles{
            id
            title
        }
    }
`;


export const AUTHORIZATIONS_ADD_QUERY = gql`
    query AuthorizationAdd {
        authroles{
            id
            title
        }
    }
`;


export const FINDING_ADD_QUERY = gql`
    query FindingAdd($whereFindingtype: FindingtypeWhereUniqueInput!){
        findingtype (where: $whereFindingtype){
            id
            title
            model
            viero
            users {
                id
            }
        }
        isoelements{
            id
            title
        }
    }
`;

export const FINDING_EDIT_QUERY = gql`
    query Finding ($where: FindingWhereUniqueInput!){
        finding(where: $where){
            id
            title
            description
            document
            finished
            oorzaak
            omvang
            oplossing
            operationaliteit
            gravity
            repeat
            critical
            controllable
            detectable
            findingtype {
                id
                model
                title
                viero
                admitted
            }
            viero
            isoelements{
                id
            }
            createdBy{
                id
                prename
                insertion
                surname
            }
            admitted{
                id
            }
            audit{
                id
                title
            }
            slidesending{
                id
                title
            }
            managementreview{
                id
                year
            }
            qualitydashboard{
                id
                quarter
            }
        }
        isoelements{
            id
            title
            paragraph
        }
        admitteds{
            id
            title
        }
    }
`;


export const MENUITEM_INDEX_QUERY = gql`
    query Menuitems($take: Int, $skip: Int, $where: MenuitemWhereInput, $orderBy: [MenuitemOrderByWithRelationInput!]){
        menuitems (take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            rang
            title
            link
            icon
            subgroup
        }
        aggregateMenuitem(where: $where){
            _count {
                id
            }
        }
    }
`;


export const MENUITEM_LIST_QUERY = gql`
    query MenuitemsList{
        menuitemsList {
            id
            rang
            title
            link
            icon
            subgroup
        }
    }
`;


export const USER_INDEX_QUERY_NEW = gql`
    query Users ($take: Int, $skip: Int, $where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!]){
        users(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            prename
            insertion
            surname
            email
            profiles {
                id
                title
            }
        }
    }
`;

export const ACTIONNAME_INDEX_QUERY = gql`
    query Actionnames ($take: Int, $skip: Int, $where: ActionnameWhereInput, $orderBy: [ActionnameOrderByWithRelationInput!]){
        actionnames(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            profiles {
                id
                title
            }
        }
        aggregateActionname(where: $where){
            _count{
                id
            }
        }
    }
`;
export const GET_ONE_DATERANGE_QUERY = gql`
    query Daterange ($where: DaterangeWhereUniqueInput!){
        daterange(where: $where){
            id
            start
            end
        }
    }
`;

export const DEVICE_EDIT_QUERY = gql`
    query Device ($where: DeviceWhereUniqueInput!){
        device(where: $where){
            id
            title
            model
            application
            inventorynumber
            received
            inservice
            outservice
            hospital
            location
            active
            manufacturer{
                id
            }
        }
        manufacturers {
            id
            title
            contact
        }
    }
`;
export const MEETING_EDIT_QUERY_NEW = gql`
    query Meeting ($where: MeetingWhereUniqueInput!){
        meeting(where: $where){
            id
            title
            date
            finished
            president {
                id
                prename
                insertion
                surname
            }
            secretary {
                id
                prename
                insertion
                surname
            }
            attendees {
                id
                prename
                insertion
                surname
            }
            text
            meetingtype {
                id
                title
                defaultSecure
            }
        }
    }
`;

export const MEETINGTYPE_PREV_NEXT_QUERY = gql`
    query MeetingPrevNext ($where: MeetingWhereUniqueInput!){
        meetingPrevNext(where: $where){
            previous{
                id
                title
                date
            }
            next{
                id
                title
                date
            }
        }
    }
`;

export const MEETINGTODO_INDEX_QUERY = gql`
    query Meetingtodos ($where: MeetingtodoWhereInput){
        meetingtodos(where: $where){
            id
            date
            finished
            description
            ${USERS_QUERY}
        }
    }
`;

export const MAINTENANCEDONE_EDIT_QUERY = gql`
    query Maintenancedone ($where: MaintenancedoneWhereUniqueInput!){
        maintenancedone(where: $where){
            id
            maintenancetype {
                id
                title
            }
            device{
                id
                title
                location
                model
            }
            description
            date
            finished
            userExternal
            needsVerification
            verificationplan
            criteria
            method
            result
            conclusion
        }
    }
`;

export const VALIDATION_EDIT_QUERY = gql`
    query Validation ($where: ValidationWhereUniqueInput!){
        validation(where: $where){
            id
            created
            finished
            description
            criteria
            method
            result
            conclusion
        }
    }
`;

export const APPOINTMENT_INDEX_QUERY = gql`
    query AppointmentIndex($filter: AppointmentIndexFilterInput){
        appointmentIndex (filter: $filter){
            id
            title
            start
            end
            location
            users{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const MANUFACTURERS_INDEX_QUERY = gql`
    query Manufacturers($where: ManufacturerWhereInput, $orderBy: [ManufacturerOrderByWithRelationInput!], $take: Int, $skip: Int){
        manufacturers(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
        }
    }
`;

export const DEVICE_INDEX_QUERY = gql`
    query DeviceIndex($filter: DeviceIndexFilterInput){
        deviceIndex (filter: $filter){
            id
            title
            model
            application
            manufacturer {
                title
            }
        }
    }
`;

export const FINDING_INDEX_QUERY = gql`
    query FindingIndex($filter: FindingIndexFilterInput!){
        findingIndex (filter: $filter){
            items{
                id
                title
                findingtype{
                    id
                    title
                }
                createdBy{
                    prename
                    insertion
                    surname
                }
                created
                updated
            }
            findingtypes {
                id
                title
            }
        }
    }
`;

export const FINDINGTYPE_INDEX_QUERY = gql`
    query FindingtypeIndex($filter: FindingtypeIndexFilterInput){
        findingtypeIndex (filter: $filter){
            id
            title
        }
    }
`;

export const FINDINGTYPE_EDIT_QUERY = gql`
    query Findingtype ($where: FindingtypeWhereUniqueInput!){
        findingtype(where: $where){
            id
            title
            model
            viero
            admitted
            meetingtypes{
                id
                title
            }
            users{
                id
                prename
                insertion
                surname
            }

        }
    }
`;


export const MAINTENANCETYPES_QUERY = gql`
    query Maintenancetypes {
        maintenancetypes {
            id
            title
            validation
        }
    }
`;

export const MAINTENANCEPLAN_EDIT_QUERY = gql`
    query MaintenanceplanEdit ($where: MaintenanceplanWhereUniqueInput!){
        maintenanceplan (where: $where){
            id
            maintenancetype {
                id
            }
            validation
            frequency
            description
        }
        maintenancetypes {
            id
            title
        }
    }
`;

export const MAINTENANCETYPE_EDIT_QUERY = gql`
    query MaintenancetypeEdit ($id: String!){
        maintenancetypeEdit(id: $id){
            maintenancetype {
                id
                title
            }
        }
    }
`;


export const MANUFACTURER_EDIT_QUERY = gql`
    query ManufacturerEdit($where: ManufacturerWhereUniqueInput!) {
        manufacturer (where: $where){
            id
            title
            contact
            manufacturerroles{
                id
                title
            }
            active
            stocks{
                id
                title
            }
            devices{
                id
                title
            }
        }
        manufacturerroles{
            id
            title
        }
    }
`;


export const MANUFACTURERREVIEW_EDIT_QUERY = gql`
    query ManufacturerreviewEdit ($id: String!){
        manufacturerreviewEdit(id: $id){
            manufacturerreview {
                id
                description
                date
                contact
                friendly
                accessability
                speed
                pricequality
                satisfaction
                yearjudgement
                yearjudgementyear
            }
        }
    }
`;


export const MANUFACTURER_YEAR_JUDGEMENT_EDIT_QUERY = gql`
    query ManufacturerYearJudgement($id: String!, $year: Int!) {
        manufacturer (where: {id: $id}){
            id
            title
            contact
            manufacturerroles{
                id
                title
            }
            active
        }
        manufacturerYearJudgement (id: $id, year: $year){
            year
            data {
                contact
                friendly
                pricequality
                accessability
                satisfaction
                speed
                reviews {
                    id
                    description
                    date
                }
            }
        }
    }
`;

export const MEETING_ADD_QUERY = gql`
    query MeetingAdd{
        meetingtypes{
            id
            title
            defaultPresident{
                id
                prename
                insertion
                surname
            }
            defaultSecretary{
                id
                prename
                insertion
                surname
            }
            defaultAttendees{
                id
                prename
                insertion
                surname
            }
            defaultSecure
        }
    }
`;

export const MEETINGTYPE_EDIT_QUERY = gql`
    query Meetingtype ($where: MeetingtypeWhereUniqueInput!){
        meetingtype(where: $where){
            id
            title
            findingtypes{
                id
            }
            defaultPresident{
                id
                prename
                insertion
                surname
            }
            defaultSecretary{
                id
                prename
                insertion
                surname
            }
            defaultAttendees{
                id
                prename
                insertion
                surname
            }
            defaultSecure
        }
        findingtypes{
            id
            title
        }
    }
`;

export const MEETINGTYPE_INDEX_QUERY = gql`
    query Meetingtypes($where: MeetingtypeWhereInput){
        meetingtypes (where: $where){
            id
            title
        }
    }
`;

export const MESSAGE_ADD_QUERY = gql`
    query MessageAdd ($id: String) {
        messageAdd (id: $id){
            message{
                id
                from{
                    id
                    prename
                    insertion
                    surname
                }
                subject
                created
                text
            }
            ${USERS_QUERY}
        }
    }
`;

export const PAGE_INDEX_QUERY = gql`
    query PageIndex($filter: PageIndexFilterInput){
        pageIndex (filter: $filter){
            id
            title
            text
        }
    }
`;

export const STOCK_MIRROR_QUERY = gql`
    query StockMirror($where: StockWhereInput, $orderBy: [StockOrderByWithRelationInput!], $take: Int, $skip: Int){
        stocks(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            minimum
            location
            hospital
            automatic
            groupedStockitems {
                status1
                status2
                status3
                status3exp
                status4
                status4exp
                status5
                shortage
            }
        }
    }
`;

export const USER_ADD_QUERY = gql`
    query UserAdd{
        userAdd{
            profiles{
                id
                title
            }
        }
    }
`;


export const MEETINGTODO_EDIT_QUERY = gql`
    query Meetingtodo ($where: MeetingtodoWhereUniqueInput!) {
        meetingtodo (where: $where){
            id
            description
            date
            finished
            ${USERS_QUERY}
        }
        profiles{
            id
            title
            ${USERS_QUERY}
        }
    }
`;


export const GET_ONE_DECISION_QUERY = gql`
    query Decision ($where: DecisionWhereUniqueInput!) {
        decision (where: $where){
            id
            description
            date
        }
    }
`;


export const QUALITYDASHBOARDITEMTYPES_INDEX_QUERY = gql`
    query Qualitydashboarditemtypes($take: Int, $skip: Int, $where: QualitydashboarditemtypeWhereInput, $orderBy: [QualitydashboarditemtypeOrderByWithRelationInput!]){
        qualitydashboarditemtypes(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            text
        }
    }
`;