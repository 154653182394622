import React, {useContext, useEffect, useState} from "react";
import {Box, ButtonGroup, IconButton, TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "../Icons/Icon";
import {FormOneFieldContext} from "./FormOneFieldWrapper";
import {MobileDatePicker, MobileDateTimePicker, MobileTimePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";


export const FormOneFieldDatetime = props => {

    const formContext = useContext(FormOneFieldContext);

    const prepareInitialValue = (i) => {
        if (i) return DateTime.fromISO(i)
        return (props.nullable) ? null : DateTime.local()
    }
    const initialValue = prepareInitialValue(formContext.initialValues[props.name])
    const loading = formContext.loading
    const [value, setValue] = useState(initialValue)
    const [error, setError] = useState('')

    //todo vergelijken met Luxon?
    const isDirty = (+value !== +initialValue) // && !isEqual(value, initialValue)

    const getIcon = () => {
        if (loading) return 'loading'
        return isDirty ? 'save' : 'check'
    }

    const components = {
        date: MobileDatePicker,
        time: MobileTimePicker,
        datetime: MobileDateTimePicker
    };
    const inputFormats = {
        date: "dd MMMM yyyy",
        time: "HH:mm",
        datetime: "dd MMMM yyyy HH:mm"
    };
    const ComponentName = components[props.type || 'datetime'];

    useEffect(() => {
        async function asyncEffect() {
            try {
                if (props.validation) {
                    await props.validation.validate(value)
                }
                setError('')
            } catch (err) {
                setError(err.errors)
            }
        }

        asyncEffect()
    }, [value]);

    function toggleNull() {
        if (value !== null) {
            setValue(null)
        } else {
            setValue(DateTime.local())
            //todo open dialog
        }
    }

    return (
        <Box sx={{marginBottom: "8px", marginTop: '16px', display: "flex"}}>
            <ComponentName
                label={`${props.label}${isDirty ? " *" : ""}`}
                error={!!error}
                helperText={error}
                ampm={false}
                inputFormat={inputFormats[props.type || 'datetime']}
                InputProps={props.nullable ? {
                    startAdornment: <InputAdornment position="start">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleNull()}
                            edge="end"
                            color={value === null ? 'default' : 'primary'}
                        >
                            <Icon name={value === null ? "toggleOff" : "toggleOn"}/>
                        </IconButton>
                    </InputAdornment>
                } : undefined}
                renderInput={(params) =>
                    <TextField
                        {...params} variant="outlined" fullWidth/>}
                variant="outlined"
                value={value}
                onChange={(v) => {
                    setValue(v)
                }}
                color={isDirty ? "secondary" : "primary"}

            />
            {isDirty &&
                <ButtonGroup sx={{display: "flex", maxHeight: "56px"}} variant={"text"}>
                    <IconButton
                        color={"warning"}
                        disabled={!isDirty || loading || !!error}
                        onClick={() => {
                            formContext.onChange({
                                [props.name]: {
                                    set: value
                                }
                            })
                        }}><Icon name={getIcon()}/></IconButton>
                    <IconButton
                        onClick={() => {
                            setValue(initialValue)
                        }}
                    ><Icon name={'reset'}/></IconButton>
                </ButtonGroup>}
        </Box>
    )
}


// <Grid container>
//     <Grid item>
//             </Grid>
//             <Grid item>
//                 <p>ini: {initialValue.toString()}</p>
//                 <p>value: {initialValue.toString()}</p>
//                 <p>dirty: {isDirty.toString()}</p>
//             </Grid>
//         </Grid>