import {gql} from '@apollo/client';
import {AUTHORIZATIONS_QUERY, FILES_QUERY, USER_QUERY, USERS_QUERY} from "./constants";

export const FILE_DELETE_MUTATION = gql`
    mutation DeleteFile($id: String!) {
        deleteFile(id: $id)
    }
`
export const DAYABSENT_TOGGLE_MUTATION = gql`
    mutation DayAbsentToggle($absentId: String, $absentreasonId: String, $date: DateTime!, $userId: String!){
        dayAbsentToggle(absentId: $absentId, absentreasonId: $absentreasonId, date: $date, userId: $userId){
            id
        }
    }
`
export const HANDLE_DAY_ACTIVITY_MUTATION = gql`
    mutation HandleDayActivity($activityId: String!, $date: DateTime!, $users: [String!]!){
        handleDayActivity(activityId: $activityId, date: $date, users: $users){
            id
            remark
            ${USER_QUERY}
        }
    }
`

export const ABSENT_EDIT_MULTIPLE_MUTATION = gql`
    mutation AbsentEditMultiple($input: AbsentEditMultipleInput!){
        absentEditMultiple(input: $input){
            id
        }
    }
`


export const USER_LOGOUT_MUTATION = gql`
    mutation UserLogout{
        userLogout{
            success
        }
    }
`;


export const AUDIT_ADD_MUTATION = gql`
    mutation CreateOneAudit ($data: AuditCreateInput!){
        createOneAudit(data: $data){
            id
            title
            subject
            notes
            isoelements{
                id
                title
                paragraph
            }
            date
            finished
            extern
            externalUsers
        }
    }
`;


export const USER_LOGIN_MUTATION = gql`
    mutation UserLogin($username: String!, $password: String!) {
        userLogin(username: $username, password: $password) {
            user {
                id
                prefix
                prename
                insertion
                surname
                username
                email
                active
                profiles {
                    id
                    title
                }
            }
            menuitems{
                id
                rang
                title
                link
                icon
                subgroup
            }
            notificationCount
        }
    }
`;
export const ACTIONNAME_EDIT_MUTATION = gql`
    mutation UpdateOneActionname($data: ActionnameUpdateInput!, $where: ActionnameWhereUniqueInput!){
        updateOneActionname(data: $data, where: $where){
            id
            title
            profiles{
                id
                title
            }
        }
    }
`;


export const ACTIONNAME_ADD_MUTATION = gql`
    mutation CreateOneActionname($data: ActionnameCreateInput!) {
        createOneActionname(data: $data){
            id
            title
            profiles{
                id
                title
            }
        }
    }
`;


export const USER_NEWPASSWORD_MUTATION = gql`
    mutation UserNewPassword($id: String!) {
        userNewPassword(id: $id){
            success
        }
    }
`;

export const USER_LOGIN_LIKE_MUTATION = gql`
    mutation UserLoginLike($id: String!) {
        userLoginLike(id: $id){
            user {
                id
                prefix
                prename
                insertion
                surname
                username
                email
                active
                profiles {
                    id
                    title
                }
            }
            menuitems{
                id
                rang
                title
                link
                icon
                subgroup
            }
            notificationCount
        }
    }
`;
export const MAINTENANCEDONE_EDIT_MUTATION = gql`
    mutation UpdateOneMaintenancedone ($data: MaintenancedoneUpdateInput!, $where: MaintenancedoneWhereUniqueInput!) {
        updateOneMaintenancedone (data: $data, where: $where){
            id
            maintenancetype {
                id
                title
            }
            device{
                id
                title
                location
                model
            }
            description
            date
            finished
            userExternal
            needsVerification
            verificationplan
            criteria
            method
            result
            conclusion
        }
    }
`;

export const VALIDATION_EDIT_MUTATION = gql`
    mutation UpdateOneValidation ($data: ValidationUpdateInput!, $where: ValidationWhereUniqueInput!) {
        updateOneValidation (data: $data, where: $where){
            id
            created
            finished
            description
            criteria
            method
            result
            conclusion
            ${AUTHORIZATIONS_QUERY}
        }
    }
`;

export const MAINTENANCEDONE_DELETE_MUTATION = gql`
    mutation DeleteOneMaintenancedone ($where: MaintenancedoneWhereUniqueInput!) {
        deleteOneMaintenancedone (where: $where){
            id
        }
    }
`;

export const UPDATE_ONE_QUALITYDASHBOARDITEM_MUTATION = gql`
    mutation UpdateOneQualitydashboarditem ($data: QualitydashboarditemUpdateInput!, $where: QualitydashboarditemWhereUniqueInput!){
        updateOneQualitydashboarditem(data: $data, where: $where){
            id
            conclusion
            evaluation
        }
    }
`;


export const MANAGEMENTREVIEW_EDIT_MUTATION = gql`
    mutation UpdateOneManagementreview ($data: ManagementreviewUpdateInput!, $where: ManagementreviewWhereUniqueInput!){
        updateOneManagementreview(data: $data, where: $where){
            id
            conclusion
        }
    }
`;


export const CREATE_ONE_DAYSTATUS_MUTATION = gql`
    mutation CreateOneDaystatus($data: DaystatusCreateInput!) {
        createOneDaystatus(data: $data){
            id
        }
    }
`;

export const DAYACTIVITY_ADD_MUTATION = gql`
    mutation CreateOneDayactivity($data: DayactivityCreateInput!) {
        createOneDayactivity(data: $data){
            id
        }
    }
`;

export const DAYACTIVITY_DELETE_MUTATION = gql`
    mutation DeleteOneDayactivity($where: DayactivityWhereUniqueInput!) {
        deleteOneDayactivity(where: $where){
            id
        }
    }
`;

export const MAINTENANCEPLAN_DELETE_MUTATION = gql`
    mutation DeleteOneMaintenanceplan($where: MaintenanceplanWhereUniqueInput!) {
        deleteOneMaintenanceplan(where: $where){
            id
        }
    }
`;
export const AUTHORIZATION_SIGN_MUTATION = gql`
    mutation AuthorizationSign($id: String!) {
        authorizationSign(id: $id){
            id
            date
            ${USER_QUERY}
        }
    }
`;

export const ACTIVITY_ADD_MUTATION = gql`
    mutation CreateOneActivity ($data: ActivityCreateInput!) {
        createOneActivity (data: $data){
            id
            title
            profile{
                id
            }
        }
    }
`;


export const ACTIVITY_EDIT_MUTATION = gql`
    mutation UpdateOneActivity($data: ActivityUpdateInput!, $where: ActivityWhereUniqueInput!) {
        updateOneActivity(data: $data, where: $where){
            id
            title
            max
            profile {
                id
            }
        }
    }
`;


export const ALERT_EDIT_MUTATION = gql`
    mutation UpdateOneAlert($data: AlertUpdateInput!, $where: AlertWhereUniqueInput!) {
        updateOneAlert(data: $data, where: $where){
            id
            title
            date
            ${USERS_QUERY}
        }
    }
`;


export const DATERANGE_DELETE_MUTATION = gql`
    mutation DeleteOneDaterange($where: DaterangeWhereUniqueInput!) {
        deleteOneDaterange(where: $where){
            id
        }
    }
`;


export const DATERANGE_ADD_MUTATION = gql`
    mutation CreateOneDaterange($data: DaterangeCreateInput!) {
        createOneDaterange (data: $data){
            id
            start
            end
            user{
                id
            }
        }
    }
`;

export const DECISION_ADD_MUTATION = gql`
    mutation CreateOneDecision($data: DecisionCreateInput!) {
        createOneDecision (data: $data){
            id
            description
            date
            meetingtype{
                id
            }
        }
    }
`;

export const DECISION_EDIT_MUTATION = gql`
    mutation UpdateOneDecision ($data: DecisionUpdateInput!, $where: DecisionWhereUniqueInput!){
        updateOneDecision(data: $data, where: $where){
            id
            description
            date
            meeting{
                id
            }
        }
    }
`;

export const TODO_ADD_MUTATION = gql`
    mutation CreateOneMeetingtodo($data: MeetingtodoCreateInput!) {
        createOneMeetingtodo (data: $data){
            id
            description
            date
            meetingtype{
                id
            }
            ${USERS_QUERY}
        }
    }
`;

export const MEETINGTODO_EDIT_MUTATION = gql`
    mutation UpdateOneMeetingtodo ($data: MeetingtodoUpdateInput!, $where: MeetingtodoWhereUniqueInput!){
        updateOneMeetingtodo(data: $data, where: $where){
            id
            description
            date
            finished
            ${USERS_QUERY}
        }
    }
`;

export const DEVICE_ADD_MUTATION = gql`
    mutation CreateOneDevice($data: DeviceCreateInput!) {
        createOneDevice (data: $data){
            id
            title
        }
    }
`;


export const FINDING_ADD_MUTATION = gql`
    mutation CreateOneFinding($data: FindingCreateInput!) {
        createOneFinding (data: $data){
            id
        }
    }
`;


export const FINDINGTYPE_ADD_MUTATION = gql`
    mutation CreateOneFindingtype ($data: FindingtypeCreateInput!) {
        createOneFindingtype (data: $data){
            id
            title
            model
        }
    }
`;



export const MAINTENANCEDONE_ADD_MUTATION = gql`
    mutation CreateOneMaintenancedone ($data: MaintenancedoneCreateInput!) {
        createOneMaintenancedone (data: $data){
            id
        }
    }
`;

export const MAINTENANCEPLAN_ADD_MUTATION = gql`
    mutation CreateOneMaintenanceplan ($data: MaintenanceplanCreateInput!) {
        createOneMaintenanceplan (data: $data){
            maintenancetype {
                id
                title
            }
            validation
            frequency
            description
            device {
                id
            }
        }
    }
`;


export const UPDATE_ONE_MAINTENANCEPLAN_MUTATION = gql`
    mutation UpdateOneMaintenanceplan ($data: MaintenanceplanUpdateInput!, $where: MaintenanceplanWhereUniqueInput!) {
        updateOneMaintenanceplan (data: $data, where: $where){
            id
            maintenancetype{
                id
            }
            validation
            frequency
            description
        }
    }
`;

export const MAINTENANCETYPE_ADD_MUTATION = gql`
    mutation CreateOneMaintenancetype ($data: MaintenancetypeCreateInput!) {
        createOneMaintenancetype (data: $data){
            id
            title
        }
    }
`;


export const MAINTENANCETYPE_EDIT_MUTATION = gql`
    mutation UpdateOneMaintenancetype ($data: MaintenancetypeUpdateInput!, $where:MaintenancetypeWhereUniqueInput! ) {
        updateOneMaintenancetype (data: $data, where: $where){
            id
            title
        }
    }
`;

export const MANUFACTURER_CREATE_MUTATION = gql`
    mutation CreateOneManufacturer ($data: ManufacturerCreateInput!) {
        createOneManufacturer (data: $data){
            id
            title
            manufacturerroles {
                id
            }
        }
    }
`;

export const MANUFACTURER_EDIT_MUTATION = gql`
    mutation UpdateOneManufacturer ($data: ManufacturerUpdateInput!, $where: ManufacturerWhereUniqueInput!){
        updateOneManufacturer(data: $data, where: $where){
            id
            title
            contact
            manufacturerroles{
                id
                title
            }
            active
            stocks{
                id
                title
            }
            devices{
                id
                title
            }
        }
    }
`;
export const MEETING_ADD_MUTATION = gql`
    mutation CreateOneMeeting ($data: MeetingCreateInput!) {
        createOneMeeting (data: $data){
            id
            title
            date
            meetingtype {
                id
                title
            }
        }
    }
`;

export const MEETINGTYPE_ADD_MUTATION = gql`
    mutation CreateOneMeetingtype ($data: MeetingtypeCreateInput!) {
        createOneMeetingtype (data: $data){
            id
            title
        }
    }
`;

export const MEETINGTYPE_EDIT_MUTATION = gql`
    mutation UpdateOneMeetingtype ($data: MeetingtypeUpdateInput!, $where: MeetingtypeWhereUniqueInput!){
        updateOneMeetingtype(data: $data, where: $where){
            id
            title
            findingtypes{
                id
            }
            defaultPresident{
                id
                prename
                insertion
                surname
            }
            defaultSecretary{
                id
                prename
                insertion
                surname
            }
            defaultAttendees{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const CREATE_ONE_MENUITEM_MUTATION = gql`
    mutation CreateOneMenuitem($data: MenuitemCreateInput!) {
        createOneMenuitem (data: $data){
            id
            title
            link
            icon
            rang
            profiles{
                id
            }
        }
    }
`;

export const DELETE_MENUITEM_MUTATION = gql`
    mutation DeleteOneMenuitem($where: MenuitemWhereUniqueInput!){
        deleteOneMenuitem(where: $where){
            id
        }
    }
`;

export const UPDATE_ONE_PROJECT_MUTATION = gql`
    mutation UpdateOneProject($data: ProjectUpdateInput!, $where: ProjectWhereUniqueInput!) {
        updateOneProject(data: $data, where: $where){
            id
            title
            start
            deadline
            finished
            remarks
            owners{
                id
                prename
                insertion
                surname
            }
            issuers{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const UPDATE_ONE_PROFILE_MUTATION = gql`
    mutation UpdateOneProfile($data: ProfileUpdateInput!, $where: ProfileWhereUniqueInput!) {
        updateOneProfile(data: $data, where: $where){
            id
            title
            menuitems{
                id
            }
        }
    }
`;

export const SLIDESENDING_ADD_MUTATION = gql`
    mutation CreateOneSlidesending ($data: SlidesendingCreateInput!) {
        createOneSlidesending (data: $data){
            id
            title
        }
    }
`;

export const SLIDESENDING_EDIT_MUTATION = gql`
    mutation UpdateOneSlidesending ($data: SlidesendingUpdateInput!, $where: SlidesendingWhereUniqueInput!) {
        updateOneSlidesending (data: $data, where: $where){
            id
            title
            location
            description
            result
            deadline
            created
            finished
            findings{
                id
            }
            ${AUTHORIZATIONS_QUERY}
            ${FILES_QUERY}
        }
    }
`;

export const STOCK_ADD_MUTATION = gql`
    mutation CreateOneStock ($data: StockCreateInput!) {
        createOneStock (data: $data){
            id
            title
        }
    }
`;


export const STOCK_EDIT_MUTATION = gql`
    mutation UpdateOneStock ($data: StockUpdateInput!, $where: StockWhereUniqueInput!){
        updateOneStock(data: $data, where: $where){
            id
            title
            text
            manufacturer{
                id
            }
            artnumberintern
            artnumberextern
            hospital
            location
            minimum
            acceptationtest
            active
            automatic
        }
    }
`;

export const CREATE_MANY_STOCKITEM_MUTATION = gql`
    mutation CreateManyStockitem ($stockitem: StockitemCreateInput!, $number: Int!) {
        createManyStockitem (stockitem: $stockitem, number: $number){
            id
            lot
            expiration
            requested
            requester{
                id
                prename
                insertion
                surname
            }
            orderer{
                id
                prename
                insertion
                surname
            }
            ordered
            order_status
            received
            received_status
            receiver{
                prename
                insertion
                surname
            }
            start
            start_status
            starter{
                prename
                insertion
                surname
            }
            end
            end_status
            ender{
                prename
                insertion
                surname
            }
        }
    }
`;


export const STOCKITEM_EDITSTEP_MUTATION = gql`
    mutation StockitemEditStep($id: String!, $action: String!, $data: JSONObject!) {
        stockitemEditStep (id: $id, action: $action, data: $data){
            id
            lot
            expiration
            expired
            requested
            requester{
                id
                prename
                insertion
                surname
            }
            ordered
            orderer{
                id
                prename
                insertion
                surname
            }
            order_status
            received
            received_status
            receiver{
                id
                prename
                insertion
                surname
            }
            start
            start_status
            starter{
                id
                prename
                insertion
                surname
            }
            end
            end_status
            ender{
                id
                prename
                insertion
                surname
            }
            stock {
                id
                acceptationtest
                title
            }
            status{
                id
                title
            }
            acceptationtest{
                id
                description
                created
                updated
            }
        }
    }
`;


export const UPDATE_ONE_QUARPA_MUTATION = gql`
    mutation UpdateOneQuarpa ($data: QuarpaUpdateInput!, $where: QuarpaWhereUniqueInput!) {
        updateOneQuarpa(data: $data, where: $where){
            id
            title
            finished
            description
        }
    }
`;

export const UPDATE_ONE_RISKGROUP_MUTATION = gql`
    mutation UpdateOneRiskgroup ($data: RiskgroupUpdateInput!, $where: RiskgroupWhereUniqueInput!) {
        updateOneRiskgroup(data: $data, where: $where){
            id
            title
            created
            description
        }
    }
`;

export const UPDATE_ONE_RISK_MUTATION = gql`
    mutation UpdateOneRisk ($data: RiskUpdateInput!, $where: RiskWhereUniqueInput!) {
        updateOneRisk(data: $data, where: $where){
            id
            act
            process
            failure
            gravity
            repeat
            critical
            controllable
            detectable
            created
            description
            riskgroup{
                id
                title
            }
            oorzaak
            omvang
            oplossing
            operationaliteit
            viero
        }
    }
`;
export const USER_ADD_MUTATION = gql`
    mutation CreateOneUser ($data: UserCreateInput!) {
        createOneUser(data: $data){
            id
            prename
            insertion
            surname
            username
            email
        }
    }
`;
export const DECISION_DELETE_MUTATION = gql`
    mutation DeleteOneDecision ($where: DecisionWhereUniqueInput!) {
        deleteOneDecision (where: $where){
            id
        }
    }
`;
export const MEETINGTODO_DELETE_MUTATION = gql`
    mutation DeleteOneMeetingtodo ($where: MeetingtodoWhereUniqueInput!) {
        deleteOneMeetingtodo (where: $where){
            id
        }
    }
`;

export const UPSERT_WORKPLAN_MUTATION = gql`
    mutation UpsertOneWorkplan($update: WorkplanUpdateInput!, $create: WorkplanCreateInput!, $where: WorkplanWhereUniqueInput!){
        upsertOneWorkplan(update: $update, create: $create, where: $where){
            id
            remarks
            date
            daypart
            department{
                id
                title
            }
            task
            workplantasks{
                id
                title
            }
            user{
                id
            }
            authorizedBy{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const UPSERT_MANY_WORKPLAN_MUTATION = gql`
    mutation UpsertManyWorkplan($userId: String!, $start: DateTime!, $end: DateTime!, $task: String, $departmentId: String, $remarks: String!, $days: [Int!]!, $dayparts: [Daypart!]!, $workplantasks: [String!]!){
        upsertManyWorkplan(userId: $userId, start: $start, end: $end, task: $task, departmentId: $departmentId, remarks: $remarks, days: $days, dayparts: $dayparts, workplantasks: $workplantasks){
            id
            remarks
            date
            daypart
            department{
                id
                title
            }
            task
            workplantasks{
                id
                title
            }
            user{
                id
            }
            authorizedBy{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const DELETE_WORKPLAN_MUTATION = gql`
    mutation DeleteOneWorkplan($where: WorkplanWhereUniqueInput!){
        deleteOneWorkplan(where: $where){
            id
        }
    }
`;
export const TOGGLE_AUTHORIZE_WORKPLAN_MUTATION = gql`
    mutation ToggleAutorizeWorkplan($where: WorkplanWhereUniqueInput!){
        toggleAutorizeWorkplan(where: $where){
            id
            remarks
            date
            daypart
            department{
                id
                title
            }
            task
            workplantasks{
                id
                title
            }
            user{
                id
            }
            authorizedBy{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const AUTHORIZE_PERIOD_WORKPLAN_MUTATION = gql`
    mutation AuthorizePeriodWorkplan($period: String!, $date: DateTime!){
        authorizePeriodWorkplan(period: $period, date: $date){
            id
            remarks
            date
            daypart
            department{
                id
                title
            }
            task
            user{
                id
            }
            authorizedBy{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const QUALITYDASHBOARD_ADD_MUTATION = gql`
    mutation CreateOneQualitydashboard ($data: QualitydashboardCreateInput!) {
        createOneQualitydashboard (data: $data){
            id
        }
    }
`;

export const CREATE_ONE_PROFILE_MUTATION = gql`
    mutation CreateOneProfile($data: ProfileCreateInput!) {
        createOneProfile(data: $data){
            id
            title
        }
    }
`;

export const CREATE_ONE_PROJECT_MUTATION = gql`
    mutation CreateOneProject($data: ProjectCreateInput!) {
        createOneProject(data: $data){
            id
            title
        }
    }
`;
