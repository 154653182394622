import React, {createRef, useContext} from "react";
import {ContentState, convertFromRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import Grid from "@mui/material/Grid";
import {Field} from "react-final-form";
import Condition from "./Condition";
import PropTypes from 'prop-types';
import SuggestionDropdown from "./SuggestionDropdown";
import {ReadOnlyContext} from "./ReadOnlyContext";
import Icon from "../../Icons/Icon";
import {Box} from "@mui/material";

const RTFForm = props => {

    const readOnlyContext = useContext(ReadOnlyContext)

    return (
        <Condition conditions={props.conditions}>
            <Grid container>
                <Grid item xs={12}>
                    <Field
                        name={props.name}
                        label={props.label}
                        toolbar={props.toolbar}
                        minHeight={props.minHeight}
                        suggestions={props.suggestions}
                        component={RTFFormWrapper}
                        readOnly={readOnlyContext}
                    />
                </Grid>
            </Grid>
        </Condition>


    )
}

export default RTFForm

RTFForm.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    toolbar: PropTypes.string,
    minHeight: PropTypes.number,
    suggestions: PropTypes.array
}
RTFForm.defaultProps = {
    toolbar: 'extensive',
    minHeight: 500,
    suggestions: []
}

const classes = {
    root: {
        //marginTop: '16px',
        marginBottom: '8px',
    },
    paper: {
        position: 'relative',
        border: '1px solid #cdcdcd',
        padding: '18.5px 14px'
    },
    label: {
        position: 'absolute',
        top: '-14px',
        left: '12px',
        backgroundColor: '#fff',
        padding: '2px',
        margin: '3px',
        fontSize: '12px',
        color: '#8f8c85',
        display: 'block'
    },
    fieldset: {
        border: "1px solid rgb(199, 199, 199)",
        borderRadius: "4px",
        paddingLeft: "11px",
        position: "relative",
    },
    legend: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px",
        fontWeight: 400
    },
    endAdornment: {
        position: "absolute",
        top: "30px",
        right: "18px"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme => theme.spacing(2),
        flex: 1,
    },
    textRight: {
        textAlign: "right"
    },
    editorWrapper: {
        // height: "500px",
        //overflowY: "scroll",
        //marginTop: "10px",
    },
    fullScreenWrapper: {
        position: "relative",
        '&:fullscreen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        },
        '&:-webkit-full-screen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        },
        '$:-moz-full-screen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        }
    },
    iconFullScreen: {
        paddingTop: 5
    }

}

const RTFFormWrapper = props => {

    const {input: {onChange, value}, label, meta, toolbar, minHeight, suggestions, readOnly} = props
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
    const icon = () => {
        if (showError) return <Icon name={"error"}/>
        if (meta.dirty) return <Icon name={"wait"}/>
        return <Icon name={"good"}/>
    }

    const [editorState, setEditorState] = React.useState(value ? EditorState.createWithContent(convertFromRaw(value)) : EditorState.createEmpty());

    //useEffect(() => {
    //const content = editorState.getCurrentContent();
    //console.log(convertToRaw(content))
    //onChange(convertToRaw(content));
    //}, [editorState])


    const changeValue = (newContentState, x) => {
        onChange(newContentState)
        //console.dir(convertToRaw(newContentState))
        //setEditorState(newEditorState)
        //onChange(convertToRaw(newContentState));

    }


    const refEditor = createRef()


    function focus() {
        if (readOnly) return
        refEditor.current.editor.focus();
    }

    const addTextToEditor = text => {
        const addContentState = ContentState.createFromText(text)
        console.dir(addContentState)
        const x = EditorState.push(editorState, addContentState, 'insert-characters')
        console.dir(x)

        setEditorState(x)
    }

//    let sx = 'RichEditor-editor';
//    var contentState = editorState.getCurrentContent();
//    if (!contentState.hasText()) {
//        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
//            sx += ' RichEditor-hidePlaceholder';
//        }
//    }


    const id = `rtf-root-id-${Math.round(Math.random() * 10000)}`

    return (
        <Box sx={classes.root}>
            <fieldset aria-hidden="true" style={{...classes.fieldset, ...{minHeight: minHeight}}} onClick={focus}
                      id={"test-root"}>
                <legend style={classes.legend}>{label}</legend>
                <SuggestionDropdown suggestions={suggestions} selectSuggestion={text => addTextToEditor(text)}/>
                <Box sx={classes.fullScreenWrapper} id={id}>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName={classes.editorWrapper}
                        editorClassName="editorClassName"
                        onEditorStateChange={setEditorState}
                        onContentStateChange={changeValue}
                        ref={refEditor}
                        {...getToolbar(toolbar, id, label, readOnly)}
                        readOnly={readOnly}
                    />
                </Box>

            </fieldset>
        </Box>
    )
}

const getToolbar = (toolbar, id, label, readOnly) => {
    if (readOnly) {
        return {
            toolbarHidden: true,
        }
    }
    if (toolbar === 'simple') {
        return {
            toolbarHidden: true,
        }
    }
    return {
        toolbarCustomButtons: [<FullScreen label={label} editorId={id}/>]
    }
}

const FullScreen = props => {

    const doFullscreen = () => {
        if (document.fullScreenElement || document.mozFullScreen || document.webkitIsFullScreen) {
            attemptExitFullscreen();
        } else {
            attemptFullscreen();
        }
    };

    const attemptFullscreen = () => {
        const element = document.getElementById(props.editorId);
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        }
    };

    const attemptExitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    };


    return (
        <Box
            className={`rdw-option-wrapper rdw-option-disabled`}
        >
            <Box sx={classes.iconFullScreen} onClick={() => doFullscreen()}>
                <Icon name={"fullscreen"}/>
            </Box>
        </Box>
    )
}