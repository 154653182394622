import React, {useContext} from "react";
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import {Field} from "react-final-form";
import Condition from "./Condition";
import {RadioDialogWrapper} from "./Wrappers/RadioDialog";
import {ReadOnlyContext} from "./ReadOnlyContext";

const RadioDialogForm = props => {
    const readOnlyContext = useContext(ReadOnlyContext)
    return (
        <Condition conditions={props.conditions}>
            <Grid container>
                <Grid item xs={12}>
                    <Field
                        component={RadioDialogWrapper}
                        readOnly={readOnlyContext}
                        {...props}
                    />
                </Grid>
            </Grid>
        </Condition>
    )
}

RadioDialogForm.defaultProps = {
    labelName: ['title'],
}
RadioDialogForm.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelName: PropTypes.array,
    options: PropTypes.array.isRequired,
    validation: PropTypes.object,
    suggestions: PropTypes.object,
}

export default RadioDialogForm