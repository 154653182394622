import React from "react";
import PropTypes from 'prop-types';
import {convertFromRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';


const classes = {
    root: {
        //marginTop: '16px',
        marginBottom: '8px',
    },
    paper: {
        position: 'relative',
        border: '1px solid #cdcdcd',
        padding: '18.5px 14px'
    },
    label: {
        position: 'absolute',
        top: '-14px',
        left: '12px',
        backgroundColor: '#fff',
        padding: '2px',
        margin: '3px',
        fontSize: '12px',
        color: '#8f8c85',
        display: 'block'
    },
    fieldset: {
        border: "1px solid rgb(199, 199, 199)",
        borderRadius: "4px",
        paddingLeft: "11px",
        position: "relative",
        marginBottom: "10px"
    },
    legend: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px",
        fontWeight: 400
    },
    endAdornment: {
        position: "absolute",
        top: "30px",
        right: "18px"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme => theme.spacing(2),
        flex: 1,
    },
    textRight: {
        textAlign: "right"
    },
    editorWrapper: {
        // height: "500px",
        //overflowY: "scroll",
        //marginTop: "10px",
    },
    fullScreenWrapper: {
        position: "relative",
        '&:fullscreen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        },
        '&:-webkit-full-screen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        },
        '$:-moz-full-screen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        }
    },
    iconFullScreen: {
        paddingTop: 5
    }

}

export const RTFReadOnlyWithLabel = (props) => {
    const editorState = EditorState.createWithContent(convertFromRaw(props.text))

    return (
        <fieldset aria-hidden="true" style={classes.fieldset} id={"test-root"}>
            <legend style={classes.legend}>{props.label}</legend>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                editorClassName="editorClassName"
                toolbarHidden={true}
            />
        </fieldset>
    )
}

RTFReadOnlyWithLabel.propTypes = {
    text: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired
}

RTFReadOnlyWithLabel.defaultProps = {}
