import {Link, useNavigate} from "react-router-dom";
import {
    Badge,
    Box,
    Button,
    Container,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import {useContext, useState} from "react";
import AuthContext from "./View/Context/AuthContext";
import Icon from "./Icons/Icon";
import {drawerWidth} from "./ConstantsFuncs/constants";
import {niceUserName} from "./ConstantsFuncs/funcs";
import UserMenuList from "./View/Users/UserMenuList";
import MainBox from "./MainBox";
import {styled} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';


const App = props => {

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [leftDrawerOpen, setLeftDrawerOpen] = useState(false)
    const toggleLeftDrawer = () => {
        setLeftDrawerOpen(!leftDrawerOpen);
    };
    const [profileMenuOpen, setProfileMenuOpen] = useState(null)
    const handleProfileMenuOpen = (e) => {
        setProfileMenuOpen(e.currentTarget)
    }

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(null);
    }


    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline/>
            <AppBar
                position="absolute"
                //sx={clsx(classes.appBar, {
                //>    [classes.appBarShift]: leftDrawerOpen,
                //})}
                open={leftDrawerOpen}
            >
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={toggleLeftDrawer}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(leftDrawerOpen && {display: 'none'}),
                        }}
                    >
                        <Icon name={"menu"}/>
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        QUARPA
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    {userContext.user.active ? (
                        <Box>
                            <IconButton
                                aria-label="Barcode"
                                color="inherit"
                                onClick={() => {
                                    navigate('/barcode/scan')
                                }}
                            >
                                <Icon name={"qrCode"}/>
                            </IconButton>
                            <IconButton
                                aria-label="Berichten"
                                color="inherit"
                                onClick={() => {
                                    navigate('/notifications/index')
                                }}
                            >
                                <Badge
                                    badgeContent={userContext.notifications}
                                    color="secondary">
                                    <Icon name={"notifications"}/>
                                </Badge>
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="Account of current user"
                                aria-controls={Boolean(profileMenuOpen) ? 'profileMenu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(profileMenuOpen) ? 'true' : undefined}
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <Badge badgeContent={userContext.user.prename}>
                                    <Icon name={"account"}/>
                                </Badge>
                            </IconButton>
                        </Box>
                    ) : (
                        <Button color="inherit" component={Link} to={"/login"}>Login</Button>
                    )}
                </Toolbar>
            </AppBar>
            {userContext.user.active && (
                <Menu
                    anchorEl={profileMenuOpen}
                    id="profileMenu"
                    keepMounted
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    open={Boolean(profileMenuOpen)}
                    onClose={handleProfileMenuClose}
                >

                    <Box>
                        <MenuItem onClick={handleProfileMenuClose} component={Link}
                                  to={"/users/self"}>
                            uw gegevens
                            ({niceUserName(userContext.user)})
                        </MenuItem>
                        <MenuItem onClick={handleProfileMenuClose} component={Link}
                                  to={"/users/changepassword"}>
                            wachtwoord wijzigen
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleProfileMenuClose();
                            userContext.logout();
                            navigate('/')

                        }}>uitloggen</MenuItem>
                    </Box>

                </Menu>
            )}

            <Drawer
                variant="persistent"
                anchor={"left"}
                open={leftDrawerOpen}
                sx={{
                    width: 240,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >

                    <IconButton onClick={toggleLeftDrawer}>
                        <Icon name={"chevronLeft"}/>
                    </IconButton>
                </Toolbar>
                <Divider/>
                {userContext.user.active && (
                    <Box>
                        <Divider/>
                        <UserMenuList/>
                    </Box>
                )}
            </Drawer>
            <TestMain
                open={leftDrawerOpen}
                component="main"
            >
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    <MainBox leftDrawerOpen={leftDrawerOpen}/>
                </Container>
            </TestMain>
        </Box>
    );
}

export default App;


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const TestMain = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: "72px",
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);