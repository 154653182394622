import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import Icon from "../../Icons/Icon";
import AuthContext from "../Context/AuthContext";
import {Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

var _ = require('lodash');


const classes = {
    nested: {
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: (theme) => theme.spacing(4),
    },
    notnested: {
        paddingTop: '2px',
        paddingBottom: '2px',
    }
}

const UserMenuList = () => {

    const [menuOpen, setMenuOpen] = useState({'': false, admin: false, types: false})

    const handleClick = key => {
        setMenuOpen({...menuOpen, ...{[key]: !menuOpen[key]}})
    }

    const userContext = useContext(AuthContext);

    // todo sortBy kan eraf na backend update
    const menuitemList = _.groupBy(_.sortBy(userContext.menuitems, ['subgroup', 'title']), item => {
        return item.subgroup
    });

    const keys = _.keys(menuitemList)
    const roosterObject = [
        {
            "id": "clagotl1z0026whowfkis451o",
            "rang": 0,
            "title": "maandrooster",
            "link": "/workplans/month",
            "icon": "calendar",
            "subgroup": "rooster"
        },
        {
            "id": "cla81oi8p0001whjcl5dzu4fd",
            "rang": 32,
            "title": "weekrooster",
            "link": "/workplans/week",
            "icon": "calendar",
            "subgroup": "rooster"
        }
    ]

    return (
        <List dense>
            <BaseListitem menuitem={{
                id: 'startitem1',
                link: '/days/dashboard',
                icon: 'ballot',
                title: 'dashboard'
            }} nested={''}/>
            <ListItemButton
                sx={classes.notnested}
                onClick={() => handleClick('roosterStart')}
            >
                <ListItemIcon>
                    <Icon name={"menu"}/>
                </ListItemIcon>
                <ListItemText primary={'rooster'}/>
                {menuOpen['roosterStart'] ? <Icon name={"chevronUp"}/> : <Icon name={"chevronDown"}/>}
            </ListItemButton>
            <Collapse in={menuOpen['roosterStart']} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {roosterObject.map((menuitem, index2) => (
                        <BaseListitem menuitem={menuitem} nested={'roosterStart'} key={index2}/>
                    ))}
                </List>
            </Collapse>
            {keys.map((key, index1) => {
                return (
                    <Box key={index1}>
                        {key && <Box>
                            <ListItemButton
                                sx={classes.notnested}
                                onClick={() => handleClick(key)}
                            >
                                <ListItemIcon>
                                    <Icon name={"menu"}/>
                                </ListItemIcon>
                                <ListItemText primary={key}/>
                                {menuOpen[key] ? <Icon name={"chevronUp"}/> : <Icon name={"chevronDown"}/>}
                            </ListItemButton>
                            <Collapse in={menuOpen[key]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding dense>
                                    {menuitemList[key].map((menuitem, index2) => (
                                        <BaseListitem menuitem={menuitem} nested={key} key={index2}/>
                                    ))}
                                </List>
                            </Collapse>
                        </Box>}
                        {!key && <Box>{menuitemList[key].map((menuitem, index2) => (
                            <BaseListitem menuitem={menuitem} nested={key} key={index2}/>
                        ))}</Box>}
                    </Box>
                )
            })}
        </List>
    )
}

export default UserMenuList


const BaseListitem = props => {


    const getIcon = () => {
        return props.menuitem.icon !== '' ? props.menuitem.icon : 'menu'
    }

    return (
        <ListItemButton
            sx={props.nested ? classes.nested : classes.notnested}
            component={Link}
            to={props.menuitem.link}
            key={props.menuitem.id}
        >
            <ListItemIcon><Icon name={getIcon()}/></ListItemIcon>

            <ListItemText
                primary={props.menuitem.title}
                primaryTypographyProps={{sx: classes.menuText}}
            />
        </ListItemButton>
    )
}