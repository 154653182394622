import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from "@mui/material";

export default function ConfirmDialog(props) {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function choosePositive() {
        if (props.choosePositive) props.choosePositive();
        handleClose()
    }

    function chooseNegative() {
        if (props.chooseNegative) props.chooseNegative();
        handleClose()
    }

    function chooseCancel() {
        if (props.chooseCancel) props.chooseCancel();
        handleClose()
    }

    return (
        <Box>
            {React.cloneElement(props.element, {onClick: handleClickOpen})}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {props.dialogTitle && <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>}
                {props.dialogContent &&
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{props.dialogContent}</DialogContentText>
                    </DialogContent>
                }
                <DialogActions>
                    <Button onClick={chooseNegative} color="primary">
                        Nee
                    </Button>
                    <Button onClick={choosePositive} color="primary" autoFocus>
                        Ja
                    </Button>
                    {props.cancelButton && <Button onClick={chooseCancel} color="primary" autoFocus>
                        Annuleren
                    </Button>}
                </DialogActions>
            </Dialog>
        </Box>
    )
}

ConfirmDialog.defaultProps = {
    icon: false,
    cancelButton: false
};