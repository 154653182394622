import {createContext} from 'react';


const AuthContext = createContext({
    user: {},
    //messages: messages,
    menuitems: [],
    notifications: [],
    loading: false,
    login: () => {
    },
    logout: () => {
    },
    userLoggedIn: () => {
    },
    userLoginLike: () => {
    },
    // user: {},
    // messages: [],
    // notifications: 0,
    // menuitems: [],
    // loading: true,
    // login: () => {
    // },
    // logout: () => {
    // },
    userInProfile: (profileIds) => {
    },
    isUser: (testUserId) => {
    },
    isInUsers: (testUserIds) => {
    },
    users: [],
    constants: [],
    profiles: [],
    getConstant: (title) => {
    }
});

export default AuthContext

