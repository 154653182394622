import React, {createRef, useContext, useEffect, useState} from "react";
import {Box, FormHelperText, IconButton} from "@mui/material";
import Icon from "../Icons/Icon";
import {FormOneFieldContext} from "./FormOneFieldWrapper";
import {convertFromRaw, convertToRaw, EditorState, Modifier} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import ConfirmDialog from "../BaseElements/ConfirmDialog";
import FieldsetWrapper from "./FieldsetWrapper";

const classes = {
    root: {
        //marginTop: '16px',
        marginBottom: '8px',
    },
    paper: {
        position: 'relative',
        border: '1px solid #cdcdcd',
        padding: '18.5px 14px'
    },
    label: {
        position: 'absolute',
        top: '-14px',
        left: '12px',
        backgroundColor: '#fff',
        padding: '2px',
        margin: '3px',
        fontSize: '12px',
        color: '#8f8c85',
        display: 'block'
    },
    fieldset: {
        border: "1px solid rgb(199, 199, 199)",
        borderRadius: "4px",
        paddingLeft: "11px",
        position: "relative",
    },
    legend: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px",
        fontWeight: 400
    },
    endAdornment: {
        position: "absolute",
        top: "30px",
        right: "18px"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme => theme.spacing(2),
        flex: 1,
    },
    textRight: {
        textAlign: "right"
    },
    editorWrapper: {
        // height: "500px",
        //overflowY: "scroll",
        //marginTop: "10px",
    },
    fullScreenWrapper: {
        position: "relative",
        '&:fullscreen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        },
        '&:-webkit-full-screen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        },
        '$:-moz-full-screen': {
            backgroundColor: "#ffffff",
            padding: 5,
            maxHeight: "100%",
            overflowY: "scroll"
        }
    },
    iconFullScreen: {
        paddingTop: "5px"
    }

}

export const FormOneFieldRTFText = props => {

    const formContext = useContext(FormOneFieldContext);
    const formContextValue = formContext.initialValues[props.name]
    const initialValue = formContextValue ? EditorState.createWithContent(convertFromRaw(formContextValue)) : EditorState.createEmpty()
    const loading = formContext.loading
    const [error, setError] = useState('')
    const [locked, setLocked] = useState(true)

    const [editorState, setEditorState] = useState(initialValue);


    useEffect(() => {
        async function asyncEffect() {
            try {
                if (props.validation) {
                    await props.validation.validate(editorState.getCurrentContent())
                }
                setError('')
            } catch (err) {
                setError(err.errors)
            }
        }

        asyncEffect()

    }, [editorState]);

    useEffect(() => {
        setEditorState(
            formContextValue ? EditorState.createWithContent(convertFromRaw(formContextValue)) : EditorState.createEmpty()
        )
    }, [formContextValue]);

    const refEditor = createRef()

    const isDirty = editorState.getUndoStack().size > 0

    function focus() {
        if (props.readOnly) return
        refEditor.current.editor.focus();
    }

    const id = `rtf-root-id-${Math.round(Math.random() * 10000)}`


    const insertX = () => {
        // const { editorState, onChange } = this.props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            '⭐',
            editorState.getCurrentInlineStyle(),
        );
        setEditorState(EditorState.push(editorState, contentState, 'insert-characters'));

    }

    const onSave = async () => {
        await formContext.onChange({
            [props.name]: convertToRaw(editorState.getCurrentContent())
        })
        setEditorState(EditorState.set(editorState, {undoStack: []}))
    }

    const resetToInitial = async () => {
        //todo werkt nog niet
        await formContext.onChange({
            [props.name]: convertToRaw(initialValue.getCurrentContent())
        })
        setEditorState(EditorState.set(editorState, {undoStack: []}))
    }

    const getToolbar = () => {
        if (props.readOnly || locked) {
            return {
                toolbarHidden: true,
            }
        }
        // if (props.toolbar === 'simple') {
        //     return {
        //         toolbarHidden: true,
        //     }
        // }
        return {
            toolbar: {
                options: props.toolbar === 'simple' ? [] : undefined,
            },
            toolbarCustomButtons: [
                <FullScreen label={props.label} editorId={id}/>,
                ...(isDirty ? [<Box
                    className={`rdw-option-wrapper`}
                >
                    <Box sx={classes.iconFullScreen} onClick={onSave}>
                        <Icon name={"save"} color={'warning'}/>
                    </Box>
                </Box>] : []),
                <Box
                    className={`rdw-option-wrapper`}
                >

                    {isDirty && <ConfirmDialog
                        element={<Box sx={classes.iconFullScreen}><Icon name={"lock"}/></Box>}
                        choosePositive={() => {
                            onSave().then(() => {
                                setLocked(true)
                            })

                        }}
                        chooseNegative={() => {
                            // console.log(editorState.getUndoStack().)
                            // setEditorState(EditorState.createWithContent(editorState.getUndoStack().get(0), {undoStack: []}))
                            //setEditorState(EditorState.set(initialValue, {}))
                            resetToInitial()
                                .then(() => {
                                    //console.log('locking')
                                    //setLocked(true)
                                })
                            //setLocked(true)
                        }}
                        chooseCancel={() => {

                        }}
                        cancelButton={true}
                        dialogTitle={"Opslaan?"}
                        dialogContent={"Wil je de wijzigingen opslaan voordat je afsluit?"}
                    />}
                    {!isDirty && <Box sx={classes.iconFullScreen} onClick={() => {
                        setLocked(true)
                    }}><Icon name={"lock"}/></Box>}

                </Box>
            ]
        }
    }

    //console.log(editorState)

    return (
        <FieldsetWrapper label={`${props.label}${isDirty ? " *" : ""}`} onClick={focus}>
            {!!error && <FormHelperText>{error}</FormHelperText>}
            {locked && <IconButton
                sx={{position: "absolute", top: 0, right: 8, zIndex: 99999}}
                onClick={() => {
                    setLocked(false)
                }}><Icon name={'pencil'}/></IconButton>}

            <Box sx={classes.fullScreenWrapper} id={id}>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName={classes.editorWrapper}
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorState}
                    ref={refEditor}
                    {...getToolbar()}
                    readOnly={props.readOnly || locked}

                />
            </Box>

        </FieldsetWrapper>
    )
}

FormOneFieldRTFText.defaultProps = {
    toolbar: 'extensive',
    minHeight: 500,
    suggestions: []
}


const FullScreen = props => {

    const doFullscreen = () => {
        if (document.fullScreenElement || document.mozFullScreen || document.webkitIsFullScreen) {
            attemptExitFullscreen();
        } else {
            attemptFullscreen();
        }
    };

    const attemptFullscreen = () => {
        const element = document.getElementById(props.editorId);
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        }
    };

    const attemptExitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    };


    return (
        <Box
            className={`rdw-option-wrapper`}
        >
            <Box sx={classes.iconFullScreen} onClick={() => doFullscreen()}>
                <Icon name={"fullscreen"}/>
            </Box>
        </Box>
    )
}