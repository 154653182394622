import {Grid} from "@mui/material";
import PropTypes from 'prop-types';
import {useMutation} from "@apollo/client";
import {errorHandler} from "../ConstantsFuncs/funcs";
import {useSnackbar} from "notistack";
import React, {useState} from "react";


export const FormOneFieldContext = React.createContext(
    {
        initialValues: null,
        loading: false,
        readOnly: false,
        onChange: () => {
        }
    }
);

const FormWrapperForOneField = props => {


    const {enqueueSnackbar} = useSnackbar()
    const [loading, setLoading] = useState(false)

    const [runMutation, {
        loading: mutationLoading,
    }] = useMutation(props.mutation, {
        onCompleted: (data) => {
            enqueueSnackbar('Opgeslagen', {variant: "success"})
            setLoading(false)
            if(props.afterSave){
                props.afterSave()
            }
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            setLoading(false)
        }
    })

    const onChange = async (data) => {
        setLoading(true)
        return runMutation({
            variables: {
                data: data,
                where: {
                    id: props.initialValues.id
                }
            }
        })
    }
    if (props.loading || !props.initialValues) return <p>loading</p>
    return (
        <FormOneFieldContext.Provider value={{
            initialValues: props.initialValues,
            onChange: onChange,
            loading: loading,
            readOnly: props.readOnly
        }}>
            <Grid container>
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            </Grid>
        </FormOneFieldContext.Provider>
    )
}


FormWrapperForOneField.propTypes = {
    mutation: PropTypes.any.isRequired,
    afterSave: PropTypes.func
}

export default FormWrapperForOneField


