import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {SnackbarProvider} from "notistack";
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from "@mui/x-date-pickers";
import AuthProvider from "./View/Context/AuthProvider";
import {AppProvider} from "./View/Context/AppContext";
import {WorkplanProvider} from "./View/Context/WorkplanContext";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

const client = new ApolloClient({
    link: createUploadLink({
        // uri: 'http://localhost:4001/graphql', //process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : 'https://graphql.quarpa.nl/graphql',
        uri: 'https://graphql.quarpa.nl/graphql',
        // uri: process.env.NODE_ENV === 'development' ? 'http://localhost:4001/graphql' : 'https://graphql.quarpa.nl/graphql',
        credentials: 'include',
        headers:
            {headers: {'Apollo-Require-Preflight': 'true'}}
    }),
    cache: new InMemoryCache({
        //addTypename: false,
        // typePolicies: {
        //     Workplan: {
        //         keyFields: ["date", "daypart", "user", ["id"]]
        //     }
        // }
    }),
    connectToDevTools: true
})


const theme = createTheme({
    status:{
        red: '#e53e3e',
        orange: '#ea905f',
        yellow: '#f1e45f',
        green: '#4fe53e',
    },
    components: {
        MuiCard: {
            defaultProps: {
                //elevation: 4,
                raised: true
            }
        }
    },
    palette: {
        green: {
            main: '#4fe53e',
            contrastText: '#eeeeee',
        },
        yellow: {
            main: '#f1e45f',
            contrastText: '#eeeeee',
        },
        orange: {
            main: '#ea905f',
            contrastText: '#eeeeee',
        },
        red: {
            main: '#e53e3e',
            contrastText: '#eeeeee',
        },
        primary: {
            //main: '#1e88e5',
            //main: '#ba0e0e',
            main: '#19457c',
            contrastText: '#eeeeee',


        }, // Purple and green play nicely together.
        secondary: {
            main: '#a1b3d3',
            contrastText: '#eeeeee'
        }, // This is just green.A700 as hex.
        neutral: {
            main: '#0b2544',
            contrastText: '#ffffff',
            light: '#ffffff',
        },
    },
});

const container = document.getElementById('root')
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'nl'}>
                    <SnackbarProvider
                        maxSnack={2}
                        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                    >
                        <AuthProvider>
                            <AppProvider>
                                <WorkplanProvider>
                                    <App/>
                                </WorkplanProvider>
                            </AppProvider>
                        </AuthProvider>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </ApolloProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
