import React, {useState} from 'react'
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from 'prop-types';
import * as _ from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {formatFormHelperText, normalizeOptions} from "../../../ConstantsFuncs/funcs";
import Icon from "../../../Icons/Icon";
import {Box} from "@mui/material";


export function RadioDialog(props) {


    const {options, onClose, value, open} = props;
    const [newValue, setNewValue] = React.useState(value);

    function handleCancel() {
        setNewValue(value);
        onClose();
    }

    function handleOk() {
        onClose(newValue);
    }

    function handleChange(event, newValue) {
        setNewValue(newValue);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Maak een keuze</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    name="ringtone"
                    value={newValue}
                    onChange={handleChange}
                >
                    {options.map(option => (
                        <FormControlLabel
                            value={option.id}
                            key={option.id}
                            control={<Radio/>}
                            label={option.label}/>
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Annuleren
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )

}

RadioDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

RadioDialog.defaultProps = {
    idName: "id",
    labelName: "title",
};


const classes = {
    root: {
        //marginTop: '16px',
        marginBottom: '8px',
    },
    paper: {
        position: 'relative',
        border: '1px solid #cdcdcd',
        padding: '18.5px 14px'
    },
    paperError: {
        position: 'relative',
        border: '1px solid #ff0000',
        padding: '18.5px 14px'
    },
    label: {
        position: 'absolute',
        top: '-14px',
        left: '12px',
        backgroundColor: '#fff',
        padding: '2px',
        margin: '3px',
        fontSize: '12px',
        color: '#8f8c85',
        display: 'block'
    },
    labelError: {
        position: 'absolute',
        top: '-14px',
        left: '12px',
        backgroundColor: '#fff',
        padding: '2px',
        margin: '3px',
        fontSize: '12px',
        color: '#ff0000',
        display: 'block'
    }
}

export function RadioDialogWrapper(props) {


    const {
        input: {name, onChange, value, ...restInput},
        label, meta, fieldProps, options, idName,
        labelName, readOnly,
        resultAsListOfStrings, resultAsInteger, resultAsString,
        ...rest
    } = props

    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;


    const icon = () => {
        if ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) return <Icon name={"error"}/>
        if (meta.dirty) return <Icon name={"wait"}/>
        return <Icon name={"check"}/>
    }

    const inputProps = {
        ...restInput, ...{
            readOnly: true,
            endAdornment: <Box><InputAdornment position="start">{icon()}</InputAdornment></Box>,
        }
    }

    const nOptions = normalizeOptions(options, idName, labelName);
    const nValue = normalizeValue(value);

    const [radioDialogOpen, setRadioDialogOpen] = useState(false);


    function normalizeValue(value) {
        if (value && typeof value === "string") {
            return {id: value}
        }
        if (Number.isInteger(value)) {
            return {id: value.toString()}
        }
        if (value && value.id) {
            return {id: value.id}
        }
        return null
    }

    function getButtonLabel() {
        if (nValue !== null && nValue.id !== null) {
            const x = _.find(nOptions, {id: nValue.id});
            if (x) return x.label
            return 'value not in options - ' + nValue.id
        }
        return 'Maak een keuze'
    }

    function onClose(newValue) {
        setRadioDialogOpen(false);
        if (newValue && !readOnly) {
            if (resultAsInteger) {
                onChange(parseInt(newValue))
            } else if (resultAsString) {
                onChange(newValue)
            } else if (resultAsListOfStrings) {
                onChange([newValue])
            } else {
                onChange({id: newValue})
            }
        }
    }

    return (
        <Box sx={classes.root}>
            <TextField
                {...rest}
                name={name}
                helperText={showError ? formatFormHelperText(meta.error || meta.submitError) : undefined}
                FormHelperTextProps={{component: 'div'}}
                error={showError}
                value={getButtonLabel()}
                InputProps={inputProps}
                onClick={() => {
                    if (!readOnly) setRadioDialogOpen(true)
                }}
                label={label}
                type="text"
                placeholder={label}
                margin="normal"
                InputLabelProps={{shrink: true}}
                variant="outlined"
                fullWidth
                disabled={readOnly}
            />
            <RadioDialog
                options={nOptions}
                value={nValue !== null ? nValue.id : ''}
                open={radioDialogOpen}
                onClose={onClose}
            />
        </Box>
    )
}

RadioDialogWrapper.propTypes = {
    readOnly: PropTypes.bool,
    resultAsInteger: PropTypes.bool,
    resultAsString: PropTypes.bool,
    resultAsListOfStrings: PropTypes.bool,
}


RadioDialogWrapper.defaultProps = {
    idName: "id",
    labelName: "title",
    readOnly: false,
    resultAsString: false,
    resultAsInteger: false,
    resultAsListOfStrings: false,
};