import React, {useState} from "react";
import {useSnackbar} from "notistack";
import {errorHandler, niceDateTime} from "../../ConstantsFuncs/funcs";
import RadioDialogForm from "../../Form/FormItems/RadioDialogForm";
import RTFForm from "../../Form/FormItems/RTFForm";
import {useMutation} from "@apollo/client";
import {UPDATE_ONE_ACCEPTATIONTEST_MUTATION, UPDATE_ONE_DATERANGE_MUTATION} from "../../ConstantsFuncs/indexMutation";
import FormWrapper from "../../FormWrapper/FormWrapper";
import ReadOnlyFormField from "../../Form/FormItems/ReadOnlyFormField";
import Button from "@mui/material/Button";
import {Box} from "@mui/material";
import FormWrapperForOneField from "../../FormOneField/FormOneFieldWrapper";
import {FormOneFieldRTFText} from "../../FormOneField/FormOneFieldRTFText";
import {FormOneFieldRadioDialogUsers} from "../../FormOneField/FormOneFieldRadioDialogUsers";


function AcceptationtestEditForm(props) {

    const {enqueueSnackbar} = useSnackbar()

    const [acceptationtest, setAcceptationtest] = useState(props.acceptationtest)
    const [updateOneAcceptationtest, {
        loading: mutationLoading,
        error: mutationError
    }] = useMutation(UPDATE_ONE_ACCEPTATIONTEST_MUTATION, {
        onCompleted: (data) => {
            enqueueSnackbar('Opgeslagen', {variant: "success"})
            setAcceptationtest(data.updateOneAcceptationtest)
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
    })


    return (
        <>
            <FormWrapperForOneField
                initialValues={acceptationtest}
                mutation={UPDATE_ONE_ACCEPTATIONTEST_MUTATION}
                readOnly={acceptationtest.finished != null}
            >
                <FormOneFieldRTFText
                    name={"description"}
                    label={"Acceptatietest"}
                />
                <FormOneFieldRadioDialogUsers
                    label={"Uitgevoerd door"}
                    name={"user"}
                />
            </FormWrapperForOneField>

            {acceptationtest.finished !== null &&
                <ReadOnlyFormField text={niceDateTime(acceptationtest.finished, "date")} label={"Afgerond"}/>}

            {acceptationtest.finished === null &&
                <Button
                    variant={"outlined"}
                    // todo werkt niet omdat form hierboven niet leidt tot update van acceptationtest in deze context
                    //disabled={acceptationtest.user === null}
                    onClick={() => {
                updateOneAcceptationtest({
                    variables: {
                        data: {finished: {set: new Date()}},
                        where: {id: acceptationtest.id}
                    }
                })
            }}>Afronden</Button>}


            {acceptationtest.finished !== null &&
                <Button variant={"outlined"} onClick={() => {
                    updateOneAcceptationtest({
                        variables: {
                            data: {finished: {set: null}},
                            where: {id: acceptationtest.id}
                        }
                    })
                }}>Heropenen</Button>}

        </>

    )
}

export default AcceptationtestEditForm