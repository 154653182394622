import {gql} from '@apollo/client';
import {AUTHORIZATIONS_QUERY, USER_QUERY, USERS_ACTIVE_QUERY, USERS_QUERY} from "./constants";

export const MEETING_INDEX_QUERY = gql`
    query Meetings ($where: MeetingWhereInput, $orderBy: [MeetingOrderByWithRelationInput!], $take: Int, $skip: Int){
        meetings(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            date
            meetingtype{
                id
                title
                defaultSecure
            }
        }
        aggregateMeeting(where: $where){
            _count{
                id
            }
        }
    }
`;

export const MANUFACTURERREVIEW_INDEX_QUERY = gql`
    query Manufacturerreviews ($where: ManufacturerreviewWhereInput, $orderBy: [ManufacturerreviewOrderByWithRelationInput!], $take: Int, $skip: Int){
        manufacturerreviews(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            date
            contact
            friendly
            accessability
            speed
            pricequality
            satisfaction
            yearjudgement
            yearjudgementyear
            ${USER_QUERY}
        }
        aggregateManufacturerreview(where: $where){
            _count{
                id
            }
        }
    }
`;


export const QUARPA_INDEX_QUERY = gql`
    query Quarpas($where: QuarpaWhereInput, $orderBy: [QuarpaOrderByWithRelationInput!], $take: Int, $skip: Int){
        quarpas(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            finished
        }
        aggregateQuarpa(where: $where){
            _count{
                id
            }
        }
    }
`;

export const PROJECT_INDEX_QUERY = gql`
    query Projects($where: ProjectWhereInput, $orderBy: [ProjectOrderByWithRelationInput!], $take: Int, $skip: Int){
        projects(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            owners{
                id
                surname
            }
        }
        aggregateProject(where: $where){
            _count{
                id
            }
        }
    }
`;
export const PROFILE_INDEX_QUERY = gql`
    query Profiles($where: ProfileWhereInput, $orderBy: [ProfileOrderByWithRelationInput!], $take: Int, $skip: Int){
        profiles(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            absentMarker
        }
        aggregateProfile(where: $where){
            _count{
                id
            }
        }
    }
`;

export const PROFILES_WITH_USERS_AND_USERS_WITH_PROFILES_QUERY = gql`
    query ProfilesWithUsersAndUsersWithProfiles{
        profiles{
            id
            title
            absentMarker
            ${USERS_ACTIVE_QUERY}
        }
        users(where: {active: {equals: true}}){
            id
            prename
            insertion
            surname
            active
            profiles {
                id
                title
            }
        }
        
    }
`;
export const PROFILES_WITH_USERS_QUERY = gql`
    query ProfilesWithUsers($where: ProfileWhereInput, $orderBy: [ProfileOrderByWithRelationInput!], $take: Int, $skip: Int, $whereUsers: UserWhereInput){
        profiles(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            users (where: $whereUsers) {
                id
                prename
                insertion
                surname
                active
            }
        }
    }
`;
export const WORKPLANS_AND_USERS_QUERY = gql`
    query Workplans($whereWorkplans: WorkplanWhereInput!, $whereUsers: UserWhereInput!, $orderUsers: [UserOrderByWithRelationInput!]){
        users(where: $whereUsers, orderBy: $orderUsers){
            id
            prename
            insertion
            surname
        }
        workplans(where: $whereWorkplans){
            id
            remarks
            date
            daypart
            department{
                id
                title
            }
            task
            user{
                id
            }
            authorizedBy{
                id
                prename
                insertion
                surname
            }
        }
    }
`;


export const WORKPLANS_QUERY = gql`
    query Workplans($where: WorkplanWhereInput, $orderBy: [WorkplanOrderByWithRelationInput!], $take: Int, $skip: Int){
        workplans(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            remarks
            date
            daypart
            department{
                id
                title
            }
            task
            workplantasks{
                id
                title
            }
            user{
                id
                prename
                insertion
                surname
            }
            authorizedBy{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const STOCKITEMORDER_INDEX_QUERY = gql`
    query GroupByStockitem($by: [StockitemScalarFieldEnum!]!, $where: StockitemWhereInput) {
        groupByStockitem(by: $by, where: $where) {
            ordered
            _count {
                _all
            }
        }
    }
`;

export const ORDER_INDEX_QUERY = gql`
    query Orders($where: OrderWhereInput, $orderBy: [OrderOrderByWithRelationInput!], $take: Int, $skip: Int){
        orders(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            date
            send
            received
        }
        aggregateOrder(where: $where){
            _count{
                id
            }
        }
    }
`;

export const ORDER_EDIT_QUERY = gql`
    query Order($where: OrderWhereUniqueInput!){
        order(where: $where){
            id
            text
            date
            send
            received
            ${USER_QUERY}
            stockitems{
                id
                received
                lot
                stock{
                    id
                    title
                }
            }
        }
    }
`;

export const MEETING_APPOINTMENT_QUERY = gql`
    query MeetingsAppoints($meeting: MeetingWhereInput, $appointment: AppointmentWhereInput){
        meetings(where: $meeting){
            id
            title
            date
        }
        appointments(where: $appointments){
            id
            title
            start
        }
    }
`;


export const CREATE_ONE_ORDER_MUTATION = gql`
    mutation CreateOneOrder ($data: OrderCreateInput!){
        createOneOrder(data: $data){
            id
            text
            date
            received
            ${USER_QUERY}
        }
    }
`;

export const UPDATE_ONE_ORDER_MUTATION = gql`
    mutation UpdateOneOrder ($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!){
        updateOneOrder(data: $data, where: $where){
            id
            text
            date
            send
            received
            ${USER_QUERY}
            stockitems{
                id
                stock{
                    id
                    title
                }
            }
        }
    }
`;

export const DELETE_MANY_STOCKITEM_MUTATION = gql`
    mutation DeleteManyStockitem ($where: StockitemWhereInput!){
        deleteManyStockitem(where: $where){
            count
        }
    }
`;

export const STOCK_W_STOCKITEMS_INDEX_QUERY = gql`
    query StocksStockitemCount($where: StockWhereInput, $orderBy: [StockOrderByWithRelationInput!], $take: Int, $skip: Int, $whereStockitems: StockitemWhereInput){
        stocks(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            location
            active
            minimum
            _count{
                stockitems(where: $whereStockitems)
            }
        }
    }
`;

export const STOCK_INDEX_QUERY = gql`
    query Stocks($where: StockWhereInput, $orderBy: [StockOrderByWithRelationInput!], $take: Int, $skip: Int){
        stocks(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            hospital
            location
            active
        }
        aggregateStock(where: $where){
            _count{
                id
            }
        }
    }
`;
export const STOCKITEM_INDEX_QUERY = gql`
    query Stockitems($where: StockitemWhereInput, $orderBy: [StockitemOrderByWithRelationInput!], $take: Int, $skip: Int){
        stockitems(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            
            order{
                id
                date
            }
            stock{
                id
                title
                hospital
                location
            }
            status{
                id
            }
            acceptationtest{
                id
                description
            }
            end
            start
            received
            ordered
            requested
            lot
            expiration
            expired
        }
        aggregateStockitem(where: $where){
            _count{
                id
            }
        }
    }
`;

export const FINDINGTYPE_DASHBOARD_QUERY = gql`
    query findingtypes($where: FindingtypeWhereInput, $orderBy: [FindingtypeOrderByWithRelationInput!], $take: Int, $skip: Int, $findingWhere: FindingWhereInput){
        findingtypes(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            model
            _count{
                findings(where: $findingWhere)
            }
        }
    }
`;


export const FINDINGTYPE_INDEX_QUERY = gql`
    query findingtypes($where: FindingtypeWhereInput, $orderBy: [FindingtypeOrderByWithRelationInput!], $take: Int, $skip: Int){
        findingtypes(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            model
            viero
        }
        aggregateFindingtype(where: $where){
            _count{
                id
            }
        }
    }
`;


export const FINDINGTYPE_INDEX_W_FINDINGS_QUERY = gql`
    query Findingtypes($where: FindingtypeWhereInput, $orderBy: [FindingtypeOrderByWithRelationInput!], $take: Int, $skip: Int, $whereFindings: FindingWhereInput){
        findingtypes(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            model
            findings(where: $whereFindings){
                id
                title
                description
                document
                isoelements{
                    id
                    title
                }
                findingtype{
                    id
                    title
                }
                created
                createdBy{
                    id
                    prename
                    insertion
                    surname
                }
                finished
                viero
                oorzaak
                omvang
                oplossing
                operationaliteit
            }
        }
    }
`;

export const FINDINGTYPE_VIEW_QUERY = gql`
    query findingtype($where: FindingtypeWhereUniqueInput!){
        findingtype(where: $where){
            id
            title
            model
        }
    }
`;

export const STOCKITEMSTATUS_INDEX_QUERY = gql`
    query Stockitemstatuses($where: StockitemstatusWhereInput, $orderBy: [StockitemstatusOrderByWithRelationInput!], $take: Int, $skip: Int){
        stockitemstatuses(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
        }
    }
`;

export const MANUFACTURERREVIEW_EDIT_QUERY = gql`
    query Manufacturerreview($where: ManufacturerreviewWhereUniqueInput!){
        manufacturerreview(where: $where){
            id
            description
            date
            contact
            friendly
            accessability
            speed
            pricequality
            satisfaction
            yearjudgement
            yearjudgementyear
        }
    }
`;

export const FINDING_INDEX_QUERY = gql`
    query Findings($where: FindingWhereInput, $orderBy: [FindingOrderByWithRelationInput!], $take: Int, $skip: Int){
        findings(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            created
            finished
            riskScore
            createdBy{
                id
                prename
                insertion
                surname
            }
            ${AUTHORIZATIONS_QUERY}
        }
        aggregateFinding(where: $where){
            _count{
                id
            }
        }
    }
`;

export const AUDIT_INDEX_QUERY = gql`
    query Audits($where: AuditWhereInput, $orderBy: [AuditOrderByWithRelationInput!], $take: Int, $skip: Int){
        audits(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            subject
            date
            finished
        }
        aggregateAudit(where: $where){
            _count{
                id
            }
        }
    }
`;

export const AUDIT_LIST_REPORT_QUERY = gql`
    query audits($where: AuditWhereInput, $orderBy: [AuditOrderByWithRelationInput!], $take: Int, $skip: Int){
        audits(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            subject
            date
            finished
            findings{
                id
                findingtype{
                    id
                    title
                }
            }
        }
        aggregateAudit(where: $where){
            _count{
                id
            }
        }
    }
`;
export const MANUFACTURER_INDEX_QUERY = gql`
    query Manufacturers($where: ManufacturerWhereInput, $orderBy: [ManufacturerOrderByWithRelationInput!], $take: Int, $skip: Int){
        manufacturers(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            active
            manufacturerroles{
                id
                title
            }
        }
        aggregateManufacturer(where: $where){
            _count{
                id
            }
        }
    }
`;
export const DEVICE_INDEX_QUERY = gql`
    query Devices($where: DeviceWhereInput, $orderBy: [DeviceOrderByWithRelationInput!], $take: Int, $skip: Int){
        devices(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            model
            application
            hospital
            location
            active
            inservice
        }
        aggregateDevice(where: $where){
            _count{
                id
            }
        }
    }
`;

export const DATERANGE_INDEX_QUERY = gql`
    query Dateranges($where: DaterangeWhereInput, $orderBy: [DaterangeOrderByWithRelationInput!], $take: Int, $skip: Int){
        dateranges(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            start
            end
        }
        aggregateDaterange(where: $where){
            _count{
                id
            }
        }
    }
`;

export const MAINTENANCEPLANS_INDEX_QUERY = gql`
    query Maintenanceplans($where: MaintenanceplanWhereInput, $orderBy: [MaintenanceplanOrderByWithRelationInput!], $take: Int, $skip: Int){
        maintenanceplans(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            maintenancetype {
                id
                title
            }
            frequency
            validation
            device {
                id
            }
        }
        aggregateMaintenanceplan(where: $where){
            _count{
                id
            }
        }
    }
`;


export const DECISION_INDEX_QUERY = gql`
    query Decisions($where: DecisionWhereInput, $orderBy: [DecisionOrderByWithRelationInput!], $take: Int, $skip: Int){
        decisions(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            date
            description
        }
        aggregateDecision(where: $where){
            _count{
                id
            }
        }
    }
`;

export const ALERT_INDEX_QUERY = gql`
    query Alerts($where: AlertWhereInput, $orderBy: [AlertOrderByWithRelationInput!], $take: Int, $skip: Int){
        alerts(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            date
            ${USERS_QUERY}
            finished
        }
        aggregateAlert(where: $where){
            _count{
                id
            }
        }
    }
`;
export const AUTHORIZATION_INDEX_QUERY = gql`
    query Authorizations($where: AuthorizationWhereInput, $orderBy: [AuthorizationOrderByWithRelationInput!], $take: Int, $skip: Int){
        authorizations(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            date
            authrole{
                id
                title
            }
            ${USER_QUERY}
        }
        aggregateAuthorization(where: $where){
            _count{
                id
            }
        }
    }
`;

export const COMMENT_INDEX_QUERY = gql`
    query Comments($where: CommentWhereInput, $orderBy: [CommentOrderByWithRelationInput!], $take: Int, $skip: Int){
        comments(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            created
            description
            ${USER_QUERY}
        }
        aggregateComment(where: $where){
            _count{
                id
            }
        }
    }
`;

export const MAINTENANCEDONES_INDEX_QUERY = gql`
    query Maintenancedones($where: MaintenancedoneWhereInput, $orderBy: [MaintenancedoneOrderByWithRelationInput!], $take: Int, $skip: Int){
        maintenancedones(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            maintenancetype {
                id
                title
            }
            device{
                id
                title
            }
            date
            finished

        }
        aggregateMaintenancedone(where: $where){
            _count{
                id
            }
        }
    }
`;

export const USER_LIST_QUERY = gql`
    query UsersList($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!], $take: Int, $skip: Int){
        users(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            prename
            insertion
            surname
        }
    }
`;
export const USERS_INDEX_QUERY = gql`
    query Users($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!], $take: Int, $skip: Int){
        users(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            prename
            insertion
            surname
            active
            email
            username
        }
        aggregateUser(where: $where){
            _count{
                id
            }
        }
    }
`;
export const APPOINTMENTS_INDEX_QUERY = gql`
    query Appointments($where: AppointmentWhereInput, $orderBy: [AppointmentOrderByWithRelationInput!], $take: Int, $skip: Int){
        appointments(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
            start
            location
        }
        aggregateAppointment(where: $where){
            _count{
                id
            }
        }
    }
`;


export const ACCEPTATIONTESTS_INDEX_QUERY = gql`
    query Acceptationtests($where: AcceptationtestWhereInput, $orderBy: [AcceptationtestOrderByWithRelationInput!], $take: Int, $skip: Int){
        acceptationtests(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            created
            stockitem{
                id
                stock{
                    id
                    title
                }
            }
        }
    }
`;

export const TEAMS_INDEX_QUERY = gql`
    query Teams($where: TeamWhereInput, $orderBy: [TeamOrderByWithRelationInput!], $take: Int, $skip: Int){
        teams(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            title
        }
    }
`;

export const DAYSTATUSES_INDEX_QUERY = gql`
    query Daystatuses($where: DaystatusWhereInput, $orderBy: [DaystatusOrderByWithRelationInput!], $take: Int, $skip: Int){
        daystatuses(where: $where, orderBy: $orderBy, take: $take, skip: $skip){
            id
            created
            text
            team{
                id
                title
            }
            ${USER_QUERY}
        }
    }
`;

export const DAYAPPOINTMENTS_INDEX_QUERY = gql`
    query Dayappointments($appointWhere: AppointmentWhereInput, $auditWhere: AuditWhereInput, $meetingWhere: MeetingWhereInput){
        appointments(where: $appointWhere){
            id
            start
            title
            location
            ${USERS_QUERY}
        }
        meetings(where: $meetingWhere){
            id
            date
            title
            meetingtype{
                id
                title
                defaultSecure
            }
        }
        audits(where: $auditWhere){
            id
            date
            title
        }
    }
`;

export const DAYACTIVITY_INDEX_QUERY = gql`
    query Dayactivities($dayActivitiesFilter: DayactivityWhereInput, $userDaterangeFilter: UserWhereInput){
        activities{
            id
            title
            showinactive
            max
            dayActivities(where: $dayActivitiesFilter){
                id
                remark
                ${USER_QUERY}
            }
            profile{
                id
                title
                users(where: $userDaterangeFilter){
                    id
                    prename
                    surname
                    insertion
                }
            }
        }
    }
`;
export const USER_VIEW_QUERY = gql`
    query Me{
        me{
            id
            prefix
            prename
            insertion
            surname
            email
        }
    }
`;