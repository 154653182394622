import React, {useContext, useState} from 'react'
import Grid from "@mui/material/Grid";
import {STOCKITEM_EDIT_QUERY} from "../../ConstantsFuncs/query";
import {useMutation, useQuery} from "@apollo/client";
import {useSnackbar} from "notistack";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import {DELETE_ONE_STOCKITEM_MUTATION, UPDATE_ONE_STOCKITEM_MUTATION} from "../../ConstantsFuncs/indexMutation";
import {errorHandler, getStickerClickItem, niceDateTime, niceUserName} from "../../ConstantsFuncs/funcs";
import {STOCKITEM_EDITSTEP_MUTATION} from "../../ConstantsFuncs/mutations";
import {EMPTY_DRAFT_OBJECT, NO_PROBLEMO_DRAFT_OBJECT} from "../../ConstantsFuncs/constants";
import ConfirmDialog from "../../BaseElements/ConfirmDialog";
import AuthContext from "../Context/AuthContext";
import Icon from "../../Icons/Icon";
import TextFieldDisabled from "../../BaseElements/TextFieldDisabled";
import {RTFReadOnlyWithLabel} from "../../BaseElements/RTFReadOnlyWithLabel";
import FormWrapperForOneField from "../../FormOneField/FormOneFieldWrapper";
import {FormOneFieldText} from "../../FormOneField/FormOneFieldText";
import {FormOneFieldDatetime} from "../../FormOneField/FormOneFieldDatetime";
import {date, string} from "yup";
import AcceptationtestEditForm from "./AcceptationtestEditForm";
import FormWrapper from "../../FormWrapper/FormWrapper";
import ReadOnlyFormField from "../../Form/FormItems/ReadOnlyFormField";
import RTFForm from "../../Form/FormItems/RTFForm";
import PropTypes from "prop-types";
import {EditorState} from "draft-js";
import {FormOneFieldRTFText} from "../../FormOneField/FormOneFieldRTFText";


const classes = {
    grid: {
        padding: "5px 20px"
    }
}


const StockitemEditDialog = (props) => {

    const {enqueueSnackbar} = useSnackbar()
    const userContext = useContext(AuthContext);
    const [stockitem, setStockitem] = useState(null)
    const [users, setUsers] = useState([])

    const {loading: queryLoading} = useQuery(STOCKITEM_EDIT_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {where: {id: props.id}},
        onError: (e) => {
            enqueueSnackbar(`Error! ${e.message}`, {variant: "error"})
        },
        onCompleted: data => {
            setStockitem(data.stockitem)
            setUsers(data.users)
        }
    });

    const [updateOneStockitem, {
        loading: mutationLoading,
        error: mutationError,
    }] = useMutation(UPDATE_ONE_STOCKITEM_MUTATION, {
        onCompleted: (data) => {
            enqueueSnackbar('Opgeslagen', {variant: "success"})
            //setStockitem(data.updateOneStockitem)
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
        refetchQueries: ['Stockitems']
    })

    const [deleteOneStockitem, {
        loading: delMutationLoading,
        error: delMutationError,
    }] = useMutation(DELETE_ONE_STOCKITEM_MUTATION, {
        onCompleted: (data) => {
            enqueueSnackbar('Verwijderd', {variant: "success"})
            props.onClose()
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
        refetchQueries: ['Stockitems']
    })

    const [stockitemEditStep, {
        loading: mutationStepLoading,
        error: mutationStepError,
    }] = useMutation(STOCKITEM_EDITSTEP_MUTATION, {
        onCompleted: (data) => {
            enqueueSnackbar("Opgeslagen")
            // setStockitem(data.stockitemEditStep.stockitem)
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
        refetchQueries: ['Stockitems']
    })


    const getTargetStatus = () => {
        const target = stockitem.status.id + 1
        switch (target) {
            case 2:
                return 'ordered'
            case 3:
                return 'received'
            case 4:
                return 'start'
            case 5:
                return 'end'
            default:
                return null
        }
    }

    if (!stockitem) return null
    const activeStep = stockitem.status.id - 1



    const showStockitemExpiredWarning = stockitem.expired && (stockitem.status.id === 3 || stockitem.status.id === 4)

    return (
        <Dialog fullWidth maxWidth={"lg"} open={props.open} onClose={() => {
            //setDrawerOpen(false)
            props.onClose()
        }}>
            <DialogTitle
                sx={{
                    backgroundColor: showStockitemExpiredWarning?'warning.main':undefined
                }}
            >
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant={"h6"} pt={1}>{`${stockitem.stock.title} - ${stockitem.lot}`}</Typography>
                    <IconButton
                        size={"large"}
                        onClick={() => {
                            getStickerClickItem([{id: stockitem.id}], 'stockitem')
                        }}
                    >
                        <Icon name={"qrCode"} fontSize={"inherit"}/>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider/>
            {showStockitemExpiredWarning &&
                <Alert
                    icon={<Icon name={'error'}/>}
                    severity={'warning'}
                >Dit item is verlopen. Neem het uit gebruik.</Alert>
            }
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Stepper activeStep={activeStep} orientation="vertical" sx={{marginBottom: "15px"}}>
                            <Step>
                                <StepLabel
                                    optional={`${niceUserName(stockitem.requester)} (${niceDateTime(stockitem.requested, 'date')})`}
                                >
                                    gemeld
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel
                                    optional={activeStep >= 1 ? `${niceUserName(stockitem.orderer)} (${niceDateTime(stockitem.ordered, 'date')})` : null}
                                >
                                    bestelling
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel
                                    optional={activeStep >= 2 ? `${niceUserName(stockitem.receiver)} (${niceDateTime(stockitem.received, 'date')})` : null}
                                >
                                    ontvangst
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel
                                    optional={activeStep >= 3 ? `${niceUserName(stockitem.starter)} (${niceDateTime(stockitem.start, 'date')})` : null}
                                >
                                    in gebruik
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel
                                    optional={activeStep >= 4 ? `${niceUserName(stockitem.ender)} (${niceDateTime(stockitem.end, 'date')})` : null}
                                >
                                    uit gebruik
                                </StepLabel>
                            </Step>
                        </Stepper>

                        <Stack direction={'row'} justifyContent={"space-between"}>
                            <Stack direction={"row"} justifyContent={"start"}>
                                {activeStep > 0 && <ResetDialog
                                    stockitem={stockitem.id}
                                    activeStep={activeStep + 1}
                                    action={updateOneStockitem}
                                />}
                                <ConfirmDialog
                                    element={
                                        <IconButton
                                            title={"delete"}
                                        >
                                            <Icon name={"delete"}/>
                                        </IconButton>
                                    }
                                    dialogTitle="Verwijderen"
                                    dialogContent="Weet je zeker dat je dit item wilt verwijderen?"
                                    choosePositive={() => {
                                        deleteOneStockitem({
                                            variables: {
                                                where: {
                                                    id: stockitem.id
                                                }
                                            }
                                        })
                                    }}
                                />

                            </Stack>
                            {stockitem.status.id < 5 && <NextStepDialog
                                stockitem={stockitem.id}
                                action={stockitemEditStep}
                                type={getTargetStatus()}/>}
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <FormWrapperForOneField
                            mutation={UPDATE_ONE_STOCKITEM_MUTATION}
                            initialValues={stockitem}
                        >
                            <FormOneFieldText
                                name={"lot"}
                                label={"Lot-nummer"}
                            />
                            <FormOneFieldDatetime
                                name={"expiration"}
                                nullable={true}
                                label={"Verloopdatum"}
                            />
                            <FormOneFieldRTFText
                                name={"order_status"}
                                label={"Opmerking bestelling"}
                                toolbar={"simple"}
                            />
                        </FormWrapperForOneField>
                        {activeStep >= 2 &&
                            <RTFReadOnlyWithLabel text={stockitem.received_status} label={"Ontvangststatus"}/>}
                        {activeStep >= 3 &&
                            <RTFReadOnlyWithLabel text={stockitem.start_status} label={"Status bij ingebruikname"}/>}
                        {activeStep >= 4 && <RTFReadOnlyWithLabel text={stockitem.end_status} label={"Eindstatus"}/>}
                    </Grid>
                    <Grid item xs={4}>
                        {stockitem.acceptationtest &&
                            <AcceptationtestEditForm
                                acceptationtest={stockitem.acceptationtest}
                            />}
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    )
}

StockitemEditDialog.propTypes =
    {
        id: PropTypes.string.isRequired
    }

export default StockitemEditDialog

const NextStepDialog = props => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [showDialog, setShowDialog] = useState(false)
    const [text, setText] = useState(EMPTY_DRAFT_OBJECT)


    const buttonText = () => {
        switch (props.type) {
            case 'ordered':
                return "Neem in bestelling"
            case 'received':
                return "Neem in ontvangst"
            case 'start':
                return "Neem in gebruik"
            case 'end':
                return "Neem uit gebruik"
            default:
                return "onbekend"
        }
    }

    const titleText = () => {
        switch (props.type) {
            case 'ordered':
                return "Opmerking bij bestelling"
            case 'received':
                return "Ontvangststatus"
            case 'start':
                return "Status ingebruikname"
            case 'end':
                return "Status uitgebruikname"
            default:
                return "onbekend"
        }
    }

    if (props.type === 'ordered') {
        return (
            <ConfirmDialog
                dialogTitle={'Besteld'}
                dialogContent={'Item is besteld'}
                choosePositive={() => {
                    props.action({
                        variables: {
                            id: props.stockitem,
                            action: props.type,
                            data: NO_PROBLEMO_DRAFT_OBJECT
                        }
                    })
                }}
                element={<Button variant="contained">{buttonText()}</Button>}
            />
        )
    }

    return (
        <>
            <Button
                variant="contained"
                onClick={async () => {
                    setShowDialog(true)
                }}
                sx={classes.button}
            >
                {buttonText()}
            </Button>
            <Dialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                }}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle>{titleText()}</DialogTitle>
                <DialogContent>
                    <FormWrapper
                        initialValues={{
                            status: NO_PROBLEMO_DRAFT_OBJECT
                        }}
                        save={fv => {
                            props.action({
                                variables: {
                                    id: props.stockitem,
                                    action: props.type,
                                    data: fv.variables.data.status
                                }
                            })
                            setShowDialog(false)
                        }}
                        autoSave={false}
                    >
                        <RTFForm
                            label={'status'}
                            name={"status"}
                            // suggestions={[
                            //     {text: "Geen afwijkingen"},
                            //     {text: "Onbruikbaar"}
                            // ]}
                            toolbar={"simple"}
                            minHeight={200}
                        />
                    </FormWrapper>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={() => {
                        setShowDialog(false)
                    }}>Annuleren</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const ResetDialog = props => {
    const [dialogOpen, setDialogOpen] = useState(false)

    const btnText = (id) => {
        switch (id) {
            case 1:
                return 'Gemeld'
            case 2:
                return 'In bestelling'
            case 3:
                return 'Ontvangen'
            case 4:
                return 'In gebruik'
            case 5:
                return 'Uit gebruik'
            default:
                return 'onbekend'
        }
    }

    const getData = (id) => {
        let data = {
            status: {connect: {id: id}},
        }

        if (id <= 4) {
            data.end = {set: null}
            data.ender = {disconnect: true}//activeStep >= 3 ? {disconnect: true} : {set: null},
            data.end_status = EMPTY_DRAFT_OBJECT
        }

        if (id <= 3) {
            data.start = {set: null}
            data.starter = {disconnect: true}//activeStep >= 2 ? {disconnect: true} : {set: null},
            data.start_status = EMPTY_DRAFT_OBJECT
        }
        if (id <= 2) {
            data.received = {set: null}
            data.receiver = {disconnect: true}//activeStep >= 1 ? {disconnect: true} : {set: null},
            data.received_status = EMPTY_DRAFT_OBJECT
        }
        if (id <= 1) {
            data.ordered = {set: null}
            data.orderer = {disconnect: true}//activeStep >= 1 ? {disconnect: true} : {set: null},
        }

        return data
    }

    return (
        <>
            <IconButton onClick={() => {
                setDialogOpen(true)
            }}><Icon name={'reset'}/></IconButton>
            <Dialog open={dialogOpen} onClose={() => {
                setDialogOpen(false)
            }}>
                <DialogTitle>Reset item</DialogTitle>
                <DialogContent>
                    <Typography>Item terugzetten naar de volgende status</Typography>
                    <Stack spacing={1}>
                        {[1, 2, 3, 4, 5].map(v => (
                            <Button
                                disabled={v >= props.activeStep}
                                onClick={() => {
                                    props.action({
                                        variables: {
                                            data: getData(v),
                                            where: {id: props.stockitem}
                                        }
                                    })
                                    setDialogOpen(false)
                                }}
                                key={v}
                                variant={"outlined"}>{btnText(v)}</Button>
                        ))}
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}