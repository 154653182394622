import React, {createContext, useState} from 'react';
import StockitemEditDialog from "../Stockitem/StockitemEditDialog";
import MeetingtodoEditDialog from "../Meetingtodo/MeetingtodoEditDialog";


export const AppContext = createContext({
    showStockitem: (id) => {
    },
    showMeetingtodo: (id) => {
    }
});


export const AppProvider = props => {

    const [stockitemId, setStockitemId] = useState(null)
    const [meetingtodoId, setMeetingtodoId] = useState(null)

    const showStockitem = (id) => {
        setStockitemId(id)
    }

    const showMeetingtodo = (id) => {
        setMeetingtodoId(id)
    }


    return (
        <AppContext.Provider value={{
            showStockitem: showStockitem,
            showMeetingtodo: showMeetingtodo,
        }}>
            {props.children}
            {Boolean(stockitemId) &&
                <StockitemEditDialog
                    id={stockitemId}
                    open={Boolean(stockitemId)}
                    onClose={() => {
                        setStockitemId(null)
                    }}
                />
            }
            {Boolean(meetingtodoId) &&
                <MeetingtodoEditDialog
                    id={meetingtodoId}
                    open={Boolean(meetingtodoId)}
                    onClose={() => {
                        setMeetingtodoId(null)
                    }}
                />
            }
        </AppContext.Provider>
    )

}
