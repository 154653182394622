import {Box} from "@mui/material";
import {Navigate, Route, Routes, useHref} from "react-router-dom";

import {lazy, Suspense, useContext} from "react";
import AuthContext from "./View/Context/AuthContext";

const SlidesendingIndex = lazy(() => import("./View/Slidesending/SlidesendingIndex"))
const SlidesendingView = lazy(() => import("./View/Slidesending/SlidesendingView"))
const MenuitemIndex = lazy(() => import("./View/Menuitem/MenuitemIndex"))
const WorkplanWeek = lazy(() => import("./View/Workplan/WorkplanWeek"))
const WorkplanMonth = lazy(() => import("./View/Workplan/WorkplanMonth"))

const FindingView = lazy(() => import("./View/Finding/FindingView"))
const FindingIndex = lazy(() => import("./View/Finding/FindingIndex"))
const FindingOverview = lazy(() => import("./View/Finding/FindingOverview"))

const DashboardIndex = lazy(() => import("./View/Dashboard/DashboardIndex"))

const StockView = lazy(() => import("./View/Stock/StockView"))
const StockEnd = lazy(() => import("./View/Stock/StockEnd"))
const StockStart = lazy(() => import("./View/Stock/StockStart"))
const StockMirror = lazy(() => import("./View/Stock/StockMirror"))
const StockIndex = lazy(() => import("./View/Stock/StockIndex"))

const PageUnknown = lazy(() => import("./View/Pages/PageUnknown"))

const AuditIndex = lazy(() => import("./View/Audit/AuditIndex"))
const AuditView = lazy(() => import("./View/Audit/AuditView"))
const AuditMirror = lazy(() => import("./View/Audit/AuditMirror"))

const ManufacturerIndex = lazy(() => import("./View/Manufacturer/ManufacturerIndex"))
const ManufacturerMirror = lazy(() => import("./View/Manufacturer/ManufacturerMirror"))
const ManufacturerView = lazy(() => import("./View/Manufacturer/ManufacturerView"))

const UserAdd = lazy(() => import("./View/Users/UserAdd"))
const UserMe = lazy(() => import("./View/Users/UserMe"))
const UserIndex = lazy(() => import("./View/Users/UserIndex"))
const UserView = lazy(() => import("./View/Users/UserView"))
const UserLogin = lazy(() => import("./View/Users/UserLogin"))
const UserForgotPassword = lazy(() => import("./View/Users/UserForgotPassword"))

const MeetingtypeIndex = lazy(() => import("./View/Meetingtype/MeetingtypeIndex"))
const MeetingtypeView = lazy(() => import("./View/Meetingtype/MeetingtypeView"))

const FindingtypeIndex = lazy(() => import("./View/Findingtype/FindingtypeIndex"))
const FindingtypeView = lazy(() => import("./View/Findingtype/FindingtypeView"))

const MaintenancetypeIndex = lazy(() => import("./View/Maintenancetype/MaintenancetypeIndex"))
const MaintenancetypeEdit = lazy(() => import("./View/Maintenancetype/MaintenancetypeEdit"))

const MeetingIndex = lazy(() => import("./View/Meeting/MeetingIndex"))
const MeetingView = lazy(() => import("./View/Meeting/MeetingView"))

const DeviceIndex = lazy(() => import("./View/Device/DeviceIndex"))
const DeviceView = lazy(() => import("./View/Device/DeviceView"))

const ActivityIndex = lazy(() => import("./View/Activity/ActivityIndex"))
const ActivityView = lazy(() => import("./View/Activity/ActivityView"))

const AppointmentIndex = lazy(() => import("./View/Appointment/AppointmentIndex"))
const AppointmentView = lazy(() => import("./View/Appointment/AppointmentView"))
const AppointmentAdd = lazy(() => import("./View/Appointment/AppointmentAdd"))

const ManagementreviewIndex = lazy(() => import("./View/Managementreview/ManagementreviewIndex"))
const ManagementreviewView = lazy(() => import("./View/Managementreview/ManagementreviewView"))
const QualitydashboardView = lazy(() => import("./View/Managementreview/QDViewNew"))
const ActionnameIndex = lazy(() => import("./View/Actionname/ActionnameIndex"))
const ActionnameView = lazy(() => import("./View/Actionname/ActionnameView"))
const BarcodeScan = lazy(() => import("./View/Barcode/BarcodeScan"))
const DayDashboard = lazy(() => import("./View/Day/DayDashboard"))
const UserChangePassword = lazy(() => import("./View/Users/UserChangePassword"))
const OrderIndex = lazy(() => import("./View/Orders/OrderIndex"))
const OrderView = lazy(() => import("./View/Orders/OrderView"))
const DeviceMaintenance = lazy(() => import("./View/Device/DeviceMaintenance"))
const MaintenancedoneView = lazy(() => import("./View/Maintenancedone/MaintenancedoneView"))
const DayRemarks = lazy(() => import("./View/Day/DayRemarks"))
const NotificationView = lazy(() => import("./View/Notification/NotificationView"))
const AuditPdf = lazy(() => import("./View/Audit/AuditPdf"))
const QuarpaView = lazy(() => import("./View/Quarpa/QuarpaView"))
const QuarpaIndex = lazy(() => import("./View/Quarpa/QuarpaIndex"))
const QuarpaPublicIndex = lazy(() => import("./View/Quarpa/QuarpaPublicIndex"))
const ProfileIndex = lazy(() => import("./View/Profiles/ProfileIndex"))
const ProfileView = lazy(() => import("./View/Profiles/ProfileView"))
const ProjectIndex = lazy(() => import("./View/Project/ProjectIndex"))
const ProjectView = lazy(() => import("./View/Project/ProjectView"))
const PageHome = lazy(() => import("./View/Pages/PageHome"))
const RiskView = lazy(() => import("./View/Risk/RiskView"))
const RiskgroupView = lazy(() => import("./View/Riskgroup/RiskgroupView"))
const RiskgroupIndex = lazy(() => import("./View/Riskgroup/RiskgroupIndex"))
const IndicatorIndex = lazy(() => import("./View/Indicator/IndicatorIndex"))
const IndicatorView = lazy(() => import("./View/Indicator/IndicatorView"))
const IndicatorDashboard = lazy(() => import("./View/Indicator/IndicatorDashboard"))


const MainBox = props => {
    const userContext = useContext(AuthContext);

    if (userContext.loading) {
        return <p>Context loading</p>
    }


    return (
        <Box sx={{flexGrow: 1}}>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<PageHome/>}/>
                    <Route path="/login" element={<UserLogin/>}/>


                    <Route path="/users/forgotpassword" element={<UserForgotPassword/>}/>


                    <Route path="/slidesendings/index"
                           element={<RequireAuth><SlidesendingIndex/></RequireAuth>}/>
                    <Route path="/slidesendings/view/:id"
                           element={<RequireAuth><SlidesendingView/></RequireAuth>}/>


                    <Route path="/menuitems/index"
                           element={<RequireAuth><MenuitemIndex/></RequireAuth>}/>


                    <Route path="/dashboards/index"
                           element={<RequireAuth><DashboardIndex/></RequireAuth>}/>

                    <Route path="/findings/index"
                           element={<RequireAuth><FindingOverview/></RequireAuth>}/>
                    <Route path="/findings/index/:id"
                           element={<RequireAuth><FindingIndex/></RequireAuth>}/>
                    <Route path="/findings/view/:id"
                           element={<RequireAuth><FindingView/></RequireAuth>}/>

                    <Route path="/stocks/index"
                           element={<RequireAuth><StockIndex/></RequireAuth>}/>
                    <Route path="/stocks/mirror"
                           element={<RequireAuth><StockMirror/></RequireAuth>}/>
                    <Route path="/stocks/start"
                           element={<RequireAuth><StockStart/></RequireAuth>}/>
                    <Route path="/stocks/end"
                           element={<RequireAuth><StockEnd/></RequireAuth>}/>
                    <Route path="/stocks/view/:id/:stockitemId"
                           element={<RequireAuth><StockView/></RequireAuth>}/>
                    <Route path="/stocks/view/:id"
                           element={<RequireAuth><StockView/></RequireAuth>}/>

                    <Route path="/orders/index"
                           element={<RequireAuth><OrderIndex/></RequireAuth>}/>
                    <Route path="/orders/view/:id"
                           element={<RequireAuth><OrderView/></RequireAuth>}/>

                    <Route path="/projects/index"
                           element={<RequireAuth><ProjectIndex/></RequireAuth>}/>
                    <Route path="/projects/view/:id"
                           element={<RequireAuth><ProjectView/></RequireAuth>}/>

                    <Route path="/riskgroups/index"
                           element={<RequireAuth><RiskgroupIndex/></RequireAuth>}/>
                    <Route path="/riskgroups/view/:id"
                           element={<RequireAuth><RiskgroupView/></RequireAuth>}/>

                    <Route path="/risks/view/:id"
                           element={<RequireAuth><RiskView/></RequireAuth>}/>


                    <Route path="/audits/index"
                           element={<RequireAuth><AuditIndex/></RequireAuth>}/>
                    <Route path="/audits/mirror"
                           element={<RequireAuth><AuditMirror/></RequireAuth>}/>
                    <Route path="/audits/view/:id"
                           element={<RequireAuth><AuditView/></RequireAuth>}/>
                    <Route path="/audits/pdf/:id"
                           element={<RequireAuth><AuditPdf/></RequireAuth>}/>


                    <Route path="/manufacturers/index"
                           element={<RequireAuth><ManufacturerIndex/></RequireAuth>}/>
                    <Route path="/manufacturers/mirror"
                           element={<RequireAuth><ManufacturerMirror/></RequireAuth>}/>
                    <Route path="/manufacturers/view/:id"
                           element={<RequireAuth><ManufacturerView/></RequireAuth>}/>


                    <Route path="/profiles/index"
                           element={<RequireAuth><ProfileIndex/></RequireAuth>}/>
                    <Route path="/profiles/view/:id"
                           element={<RequireAuth><ProfileView/></RequireAuth>}/>

                    <Route path="/indicators/dashboard"
                           element={<RequireAuth><IndicatorDashboard/></RequireAuth>}/>
                    <Route path="/indicators/index"
                           element={<RequireAuth><IndicatorIndex/></RequireAuth>}/>
                    <Route path="/indicators/view/:id"
                           element={<RequireAuth><IndicatorView/></RequireAuth>}/>

                    <Route path="/users/index"
                           element={<RequireAuth><UserIndex/></RequireAuth>}/>
                    <Route path="/users/view/:id"
                           element={<RequireAuth><UserView/></RequireAuth>}/>
                    <Route path="/users/add" element={<RequireAuth><UserAdd/></RequireAuth>}/>
                    <Route path="/users/self" element={<RequireAuth><UserMe/></RequireAuth>}/>
                    <Route path="/users/changepassword"
                           element={<RequireAuth><UserChangePassword/></RequireAuth>}/>

                    <Route path="/meetingtypes/index"
                           element={<RequireAuth><MeetingtypeIndex/></RequireAuth>}/>
                    <Route path="/meetingtypes/view/:id"
                           element={<RequireAuth><MeetingtypeView/></RequireAuth>}/>

                    <Route path="/findingtypes/index"
                           element={<RequireAuth><FindingtypeIndex/></RequireAuth>}/>
                    <Route path="/findingtypes/view/:id"
                           element={<RequireAuth><FindingtypeView/></RequireAuth>}/>


                    <Route path="/maintenancetypes/index"
                           element={<RequireAuth><MaintenancetypeIndex/></RequireAuth>}/>
                    <Route path="/maintenancetypes/view/:id"
                           element={<RequireAuth><MaintenancetypeEdit/></RequireAuth>}/>

                    <Route path="/meetings/index"
                           element={<RequireAuth><MeetingIndex/></RequireAuth>}/>
                    <Route path="/meetings/view/:id"
                           element={<RequireAuth><MeetingView/></RequireAuth>}/>

                    <Route path="/days/dashboard/:year/:month/:day"
                           element={<RequireAuth><DayDashboard/></RequireAuth>}/>
                    <Route path="/days/dashboard/:date"
                           element={<RequireAuth><DayDashboard/></RequireAuth>}/>
                    <Route path="/days/dashboard" element={<RequireAuth><DayDashboard/></RequireAuth>}/>

                    <Route path="/days/remarks"
                           element={<RequireAuth><DayRemarks/></RequireAuth>}/>

                    <Route path="/devices/index"
                           element={<RequireAuth><DeviceIndex/></RequireAuth>}/>
                    <Route path="/devices/maintenance"
                           element={<RequireAuth><DeviceMaintenance/></RequireAuth>}/>
                    <Route path="/devices/view/:id"
                           element={<RequireAuth><DeviceView/></RequireAuth>}/>

                    <Route path="/maintenancedones/view/:id"
                           element={<RequireAuth><MaintenancedoneView/></RequireAuth>}/>

                    <Route path="/activities/index"
                           element={<RequireAuth><ActivityIndex/></RequireAuth>}/>
                    <Route path="/activities/view/:id"
                           element={<RequireAuth><ActivityView/></RequireAuth>}/>
                    <Route path="/appointments/index"
                           element={<RequireAuth><AppointmentIndex/></RequireAuth>}/>
                    <Route path="/appointments/view/:id"
                           element={<RequireAuth><AppointmentView/></RequireAuth>}/>

                    <Route
                        path="/appointments/add"
                        element={<RequireAuth><AppointmentAdd/></RequireAuth>}
                    />
                    <Route
                        path="/managementreviews/index"
                        element={<RequireAuth><ManagementreviewIndex/></RequireAuth>}
                    />
                    <Route
                        path="/managementreviews/view/:id"
                        element={<RequireAuth><ManagementreviewView/></RequireAuth>}
                    />
                    <Route
                        path="/qualitydashboards/view/:id"
                        element={<RequireAuth><QualitydashboardView/></RequireAuth>}
                    />
                    <Route
                        path="/actionnames/index"
                        element={<RequireAuth><ActionnameIndex/></RequireAuth>}
                    />
                    <Route
                        path="/actionnames/view/:id"
                        element={<RequireAuth><ActionnameView/></RequireAuth>}
                    />
                    <Route
                        path="/barcode/scan"
                        element={<RequireAuth><BarcodeScan/></RequireAuth>}
                    />
                    <Route
                        path="/quarpas/adminindex"
                        element={<RequireAuth><QuarpaIndex/></RequireAuth>}
                    />
                    <Route
                        path="/quarpas/adminview/:id"
                        element={<RequireAuth><QuarpaView/></RequireAuth>}
                    />
                    <Route
                        path="/quarpas/index"
                        element={<RequireAuth><QuarpaPublicIndex/></RequireAuth>}
                    />
                    <Route
                        path="/notifications/index"
                        element={<RequireAuth><NotificationView/></RequireAuth>}
                    />

                    <Route
                        path="/workplans/week"
                        element={<RequireAuth><WorkplanWeek/></RequireAuth>}
                    />
                    <Route
                        path="/workplans/week/:year/:month/:day"
                        element={<RequireAuth><WorkplanWeek/></RequireAuth>}
                    />
                    <Route
                        path="/workplans/week/:year/:week"
                        element={<RequireAuth><WorkplanWeek/></RequireAuth>}
                    />
                    <Route
                        path="/workplans/month"
                        element={<RequireAuth><WorkplanMonth/></RequireAuth>}
                    />

                    <Route component={PageUnknown}/>
                </Routes>
            </Suspense>
        </Box>
    )
}

export default MainBox

const RequireAuth = ({children, redirectTo}) => {
    const href = useHref();
    const userContext = useContext(AuthContext);
    return userContext.userLoggedIn() ? children :
        <Navigate to={redirectTo + (href ? `?redirect=${encodeURIComponent(href)}` : '')}/>
}
RequireAuth.defaultProps = {
    redirectTo: "/login"
}