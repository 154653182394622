import React from "react";
import {Box} from "@mui/material";


const classes = {
    root: {
        marginTop: '6px',
        marginBottom: '8px',
    },
    fieldset: {
        border: "1px solid rgb(199, 199, 199)",
        borderRadius: "4px",
        paddingLeft: "11px",
        position: "relative",
        display: "flex"
    },
    legend: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px",
        fontWeight: 400
    }
}

const FieldsetWrapper = props => {

    return (
        <Box sx={classes.root}>
            <fieldset aria-hidden="true" style={classes.fieldset}>
                <legend style={classes.legend}>{props.label}</legend>
                {props.children}
            </fieldset>
        </Box>
    )
}

export default FieldsetWrapper