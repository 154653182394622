import React from 'react'
import PropTypes from 'prop-types';
import {
    AccessAlarm,
    AccountCircle,
    Add,
    AddShoppingCart,
    AssignmentTurnedIn,
    Badge,
    Ballot,
    Biotech,
    Bolt,
    Build,
    Calculate,
    CalendarToday,
    Check,
    Verified,
    CheckBox,
    CheckBoxOutlineBlank,
    ChevronLeft,
    ChevronRight,
    Code,
    Delete,
    Description,
    DirectionsRun,
    Edit,
    Event,
    EventAvailable,
    EventBusy,
    EventRepeat,
    ExpandLess,
    ExpandMore,
    Factory,
    Fax,
    FiberManualRecord,
    FilterList,
    FlashOn,
    Folder,
    Forum,
    Fullscreen,
    Group,
    HourglassEmpty,
    Info,
    Launch,
    Lightbulb,
    ListAlt,
    Lock,
    LockOpen,
    Login,
    Logout,
    Mail,
    Menu,
    MiscellaneousServices,
    NavigateNext,
    Notifications,
    Password,
    Person,
    PictureAsPdf,
    PunchClock,
    QrCode,
    RadioButtonChecked,
    RadioButtonUnchecked,
    Replay,
    RestartAlt,
    Save,
    Search,
    SentimentDissatisfied,
    SentimentSatisfied,
    SentimentSatisfiedAlt,
    SentimentVeryDissatisfied,
    SentimentVerySatisfied,
    Star,
    ThumbDown,
    ThumbUp,
    Today,
    ToggleOff,
    ToggleOn,
    Traffic,
    ViewInAr,
    Visibility,
    VisibilityOff,
    WarningAmber, ShowChart
} from '@mui/icons-material';

// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {
//     faBusinessTime,
//     faCalculator,
//     faCalendarAlt,
//     faCalendarCheck,
//     faCalendarTimes,
//     faCashRegister,
//     faCogs,
//     faCubes,
//     faEdit,
//     faEye,
//     faEyeSlash,
//     faFilePdf,
//     faGavel,
//     faHandshake,
//     faIdBadge,
//     faIndustry,
//     faKey,
//     faLightbulb,
//     faMicroscope,
//     faQrcode,
//     faRunning,
//     faSearch,
//     faSignInAlt,
//     faSignOutAlt,
//     faStar,
//     faTasks,
//     faUser,
//     faUsers,
//     faWrench
// } from "@fortawesome/free-solid-svg-icons";
// import {faRedo} from "@fortawesome/free-solid-svg-icons/faRedo";
// import {faCode} from "@fortawesome/free-solid-svg-icons/faCode";

const Icon = React.forwardRef(function MyComponent(props, ref) {

    const {name, ...rest} = props
    switch (name) {
        case 'absent':
            return <EventBusy {...rest}/>
        case 'account':
            return <AccountCircle {...rest}/>
        case 'add':
            return <Add {...rest}/>
        case 'addShoppingCart':
            return <AddShoppingCart {...rest}/>
        case 'alarm':
            return <AccessAlarm {...rest}/>
        case 'alert':
            return <AccessAlarm {...rest}/>
        case 'appointment':
            return <Event {...rest}/>
        case 'ballot':
            return <Ballot {...rest}/>
        case 'bolt':
            return <Bolt {...rest}/>
        case 'calculator':
            return <Calculate {...rest}/>
        case 'calendar':
            return <CalendarToday {...rest}/>
        case 'calendarAuth':
            return <EventAvailable {...rest}/>
        case 'chart':
            return <ShowChart {...rest}/>
        case 'check':
            return <Check {...rest}/>
        case 'checklist':
            return <Verified {...rest}/>
        case 'checkboxChecked':
            return <CheckBox {...rest}/>
        case 'checkboxUnchecked':
            return <CheckBoxOutlineBlank {...rest} />
        case 'cogs':
            return <MiscellaneousServices {...rest}/>
        case 'code':
            return <Code {...rest}/>
        case 'comment':
            return <Forum {...rest}/>
        case 'chevronLeft':
            return <ChevronLeft {...rest}/>
        case 'chevronRight':
            return <ChevronRight {...rest}/>
        case 'chevronDown':
            return <ExpandMore {...rest}/>
        case 'chevronUp':
            return <ExpandLess {...rest}/>
        case 'cubes':
            return <ViewInAr {...rest}/>
        case 'delete':
            return <Delete {...rest}/>
        case 'description':
            return <Description {...rest}/>
        case 'device':
            return <Fax {...rest}/>
        case 'dot':
            return <FiberManualRecord {...rest}/>
        case 'eye':
            return <Visibility {...rest}/>
        case 'eyeClosed':
            return <VisibilityOff {...rest}/>
        case 'error':
            return <WarningAmber {...rest}/>
        case 'event':
            return <Event {...rest}/>
        case 'eventRepeat':
            return <EventRepeat {...rest}/>
        case 'filter':
            return <FilterList {...rest}/>
        case 'folder':
            return <Folder {...rest}/>
        case 'fullscreen':
            return <Fullscreen {...rest}/>
        case 'gavel':
            return <Build {...rest}/>
        case 'handshake':
            return <AssignmentTurnedIn {...rest}/>
        case 'idBadge':
            return <Badge {...rest}/>
        case 'info':
            return <Info {...rest} ref={ref}/>
        case 'industry':
            return <Factory {...rest}/>
        case 'lightBulb':
            return <Lightbulb {...rest}/>
        case 'lock':
        case 'secure':
            return <Lock {...rest}/>
        case 'unlock':
        case 'insecure':
            return <LockOpen {...rest}/>
        case 'launch':
            return <Launch {...rest}/>
        case 'maintenance':
            return <Build {...rest}/>
        case 'maintenanceplanned':
            return <PunchClock {...rest}/>
        case 'mail':
            return <Mail {...rest}/>
        case 'menu':
            return <Menu {...rest}/>
        case 'microscope':
            return <Biotech {...rest}/>
        case 'notifications':
            return <Notifications {...rest}/>
        case 'password':
            return <Password {...rest}/>
        case 'pdf':
            return <PictureAsPdf {...rest}/>
        case 'pencil':
            return <Edit {...rest}/>
        case 'qrCode':
            return <QrCode {...rest}/>
        case 'radiobuttonChecked':
            return <RadioButtonChecked {...rest}/>
        case 'radiobuttonUnchecked':
            return <RadioButtonUnchecked {...rest} />
        case 'reopen':
            return <Replay {...rest}/>
        case 'reset':
            return <RestartAlt {...rest}/>
        case 'running':
            return <DirectionsRun {...rest}/>
        case 'save':
            return <Save {...rest}/>
        case 'search':
            return <Search {...rest}/>
        case 'veryDissatisfiedIcon':
            return <SentimentVeryDissatisfied {...rest}/>
        case 'dissatisfiedIcon':
            return <SentimentDissatisfied {...rest}/>
        case 'satisfiedIcon':
            return <SentimentSatisfied {...rest}/>
        case 'satisfiedAltIcon':
            return <SentimentSatisfiedAlt {...rest}/>
        case 'verySatisfiedIcon':
            return <SentimentVerySatisfied {...rest}/>
        case 'today':
            return <Today {...rest}/>
        case 'thumbDown':
            return <ThumbDown {...rest}/>
        case 'thumbUp':
            return <ThumbUp {...rest}/>
        case 'traffic':
            return <Traffic {...rest}/>
        case 'separator':
            return <NavigateNext {...rest}/>
        case 'signIn':
            return <Login {...rest}/>
        case 'signOut':
            return <Logout {...rest}/>
        case 'speeddial':
            return <FlashOn {...rest}/>
        case 'star':
            return <Star {...rest}/>
        case 'tasks':
            return <ListAlt {...rest}/>
        case 'toggleOff':
            return <ToggleOff {...rest}/>
        case 'toggleOn':
            return <ToggleOn {...rest}/>
        case 'user':
            return <Person {...rest}/>
        case 'users':
            return <Group {...rest}/>
        case 'wait':
        case 'loading':
        case 'saving':
        case 'hourglass':
            return <HourglassEmpty {...rest}/>
        default:
            console.log('Missing Icon', name)
            return (
                <Add {...rest}/>
            )
    }
})

export default Icon

Icon.propTypes = {
    name: PropTypes.string.isRequired
}