import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const ReadOnlyFormField = props => {

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    value={props.text}
                    label={props.label}
                    placeholder={props.label}
                    margin="normal"
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    fullWidth
                    disabled={true}
                />
            </Grid>
        </Grid>
    )
}

export default ReadOnlyFormField