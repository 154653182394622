import React from "react";
import PropTypes from 'prop-types';
import {Field} from "react-final-form";
import {Box} from "@mui/material";


const Condition = props => {

    const createConditions = (conditions, children) => {
        let accum = children
        for (let condition of conditions) {
            accum = createCondition(condition, accum)
            if (!accum) return null // als eerste conditie faalt, direct returnen
        }
        return accum
    }

    const createCondition = (condition, accum) => {
        return (
            <Field name={condition.name} subscription={{value: true}}>
                {({input: {value}}) => {
                    switch (condition.rule) {
                        case 'valueIs':
                            return value === condition.value ? accum : null
                        case 'valueIsNot':
                            return value !== condition.value ? accum : null
                    }
                    return accum
                }}
            </Field>
        )
    }

    if (props.conditions) {
        return (
            <Box>
                {createConditions(props.conditions, props.children)}
            </Box>
        )
    }
    return (<Box>{props.children}</Box>)
}

Condition.propTypes = {
    conditions: PropTypes.arrayOf(PropTypes.object)
}

export default Condition